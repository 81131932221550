<div class="tabs-nav" *ngIf="profileForm">
    <a class="tabs-nav__btn" [class.tabs-nav__btn--active]="tab == 1" (click)="tab = 1">Мои данные</a>
    <a class="tabs-nav__btn" [class.tabs-nav__btn--active]="tab == 2" (click)="tab = 2">Юр.лица</a>
</div>
<app-whoops [whoopsMsg]="whoopsMsg"></app-whoops>

<div *ngIf="!whoopsMsg && !profileForm">{{ config.defaultMsg }}</div>

<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TAB 1 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<div *ngIf="tab == 1">
<form *ngIf="profileForm" [formGroup]="profileForm" (ngSubmit)="updateProfile()">
    <div class="box">
        <div class="flex flex-wrap">
            <div class="form-group form-group--1x3">
                <input class="form-element form-element--sm form-element--filled" id="user-name-1" type="text" formControlName="first_name"/>
                <label class="form-label" for="user-name-1">
                    Имя <span class="err-msg">{{ f.first_name['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group form-group--1x3">
                <input class="form-element form-element--sm form-element--filled" id="user-surname-1" type="text" formControlName="last_name"/>
                <label class="form-label" for="user-surname-1">
                    Фамилия <span class="err-msg">{{ f.last_name['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group form-group--1x3">
                <input class="form-element form-element--sm form-element--filled" id="user-typography-name-1" type="text" formControlName="typography"/>
                <label class="form-label" for="user-typography-name-1">
                    Название типографии <span class="err-msg">{{ f.typography['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <input class="form-element form-element--sm form-element--filled" id="user-email-1" type="email" [value]="user.email || ''" readonly title="для изменения email свяжитесь с нами" />
                <label class="form-label" for="user-email-1">
                    Логин пользователя (Email) <span class="err-msg">{{ f.email['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <input class="form-element form-element--sm form-element--filled" id="user-tel-1" formControlName="telefon" appInputPhone />
                <label class="form-label" for="user-tel-1">
                    Контактный телефон <span class="err-msg">{{ f.telefon['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group--1x3 form-group--no-margin-bottom t-a-center">
                <a class="btn btn--main" (click)="showEditPasswordForm()">Изменить пароль</a>
            </div>
        </div>
    </div>

    <div class="box user-area--settings">
        <div class="text-level-3">Настройки уведомлений</div>
        <div class="flex flex-wrap">
            <!--deploymentordermail-->
            <div class="form-group form-group--1x3">
                <div class="checkbox checkbox--md checkbox--a-i-top">
                    <input class="checkbox__input" id="order-placement-1" type="checkbox" value="1" formControlName="deploymentorder" />
                    <label class="checkbox__label" for="order-placement-1">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg>
                    </label>
                    <label class="checkbox__caption" for="order-placement-1">
                        <span>Размещение заказа</span>
                        <br/>
                        <input type="text"
                               class="form-element form-element--sm form-element-change-email"
                               formControlName="deploymentordermail"
                               [placeholder]="rxForm.value.email">
                        <svg class="svg-icon change-email-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#pen"></use>
                        </svg>
                    </label>
                </div>
            </div>
            <!--orderforshipmentmail-->
            <div class="form-group form-group--1x3">
                <div class="checkbox checkbox--md checkbox--a-i-top">
                    <input class="checkbox__input" id="ready-for-shipment-1" type="checkbox" value="1" formControlName="orderforshipment" />
                    <label class="checkbox__label" for="ready-for-shipment-1">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg>
                    </label>
                    <label class="checkbox__caption" for="ready-for-shipment-1">
                        <span>Готовность заказа к отгрузке</span>
                        <br/>
                        <input type="text"
                               class="form-element form-element--sm form-element-change-email"
                               formControlName="orderforshipmentmail"
                               [placeholder]="rxForm.value.email">
                        <svg class="svg-icon change-email-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#pen"></use>
                        </svg>
                    </label>
                </div>
            </div>
            <!--startorderinproductionmail-->
            <div class="form-group form-group--1x3">
                <div class="checkbox checkbox--md checkbox--a-i-top">
                    <input class="checkbox__input" id="order-in-production-1" type="checkbox" value="1" formControlName="startorderinproduction"/>
                    <label class="checkbox__label" for="order-in-production-1">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg>
                    </label>
                    <label class="checkbox__caption" for="order-in-production-1">
                        <span>Запуск заказа в производство</span>
                        <br/>
                        <input type="text"
                               class="form-element form-element--sm form-element-change-email"
                               formControlName="startorderinproductionmail"
                               [placeholder]="rxForm.value.email">
                        <svg class="svg-icon change-email-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#pen"></use>
                        </svg>
                    </label>
                </div>
            </div>
            <!--courierordermail-->
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <div class="checkbox checkbox--md checkbox--a-i-top">
                    <input class="checkbox__input" id="transferred-to-courier-1" type="checkbox" value="1" formControlName="courierorder"/>
                    <label class="checkbox__label" for="transferred-to-courier-1">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg>
                    </label>
                    <label class="checkbox__caption" for="transferred-to-courier-1">
                        <span>Передача курьерской службе</span>
                        <br/>
                        <input type="text"
                               class="form-element form-element--sm form-element-change-email"
                               formControlName="courierordermail"
                               [placeholder]="rxForm.value.email">
                        <svg class="svg-icon change-email-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#pen"></use>
                        </svg>
                    </label>
                </div>
            </div>
            <!--deploymentpreordermail-->
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <div class="checkbox checkbox--md checkbox--a-i-top">
                    <input class="checkbox__input" id="calculation-1" type="checkbox" value="1" formControlName="deploymentpreorder"/>
                    <label class="checkbox__label" for="calculation-1">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg>
                    </label>
                    <label class="checkbox__caption" for="calculation-1">
                        <span>Размещение расчета</span>
                        <br/>
                        <input type="text"
                               class="form-element form-element--sm form-element-change-email"
                               formControlName="deploymentpreordermail"
                               [placeholder]="rxForm.value.email">
                        <svg class="svg-icon change-email-icon" viewBox="0 0 1 1" width="10" height="10">
                            <use xlink:href="/assets/img/sprite.svg#pen"></use>
                        </svg>
                    </label>
                </div>
            </div>
        </div>
    </div>


    <div class="flex j-c-end m-t-12">
        <button class="btn btn--main btn--lg" type="submit">Сохранить изменения</button>
    </div>
</form>
</div>



<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TAB 2 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<div class="auto-v-margins" *ngIf="tab == 2">

    <div *ngIf="user.contragents.length == 0">У вас еще нет юр.лиц</div>

    <section class="accordeon"
             *ngFor="let contragent of user.contragents"
             [class.accordeon--opened]="contragentActive == contragent.uidcontragent1c">

        <div class="accordeon__header" (click)="showContragentDetails(contragent.uidcontragent1c)">
            <h3 class="accordeon__caption">{{ contragent.namejur  }}</h3>
            <div class="accordeon__mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="8" height="8">
                    <use xlink:href="/assets/img/sprite.svg#angle-to-right"></use>
                </svg>
            </div>
        </div>

        <div class="accordeon__content accordeon__content--sm-bottom-padding" [class.hidden]="contragentActive != contragent.uidcontragent1c">
            <div class="legal-entity">
                <div class="flex flex-wrap">
                    <div class="form-group form-group--1x3 form-group">
                        <div class="form-element form-element--sm form-element--filled">{{ contragent.namejur }}</div>
                        <div class="form-label">Название юридического лица</div>
                    </div>
                    <div class="form-group form-group--1x3 form-group">
                        <div class="form-element form-element--sm form-element--filled">{{ contragent.inn }}</div>
                        <div class="form-label">ИНН</div>
                    </div>
                    <div class="form-group form-group--1x3 form-group">
                        <div class="form-element form-element--sm form-element--filled">
                            {{ contragent.contractname }}
                        </div>
                        <div class="form-label">Договор</div>
                    </div>
                    <div class="form-group">
                        <div class="form-element form-element--sm form-element--filled">
                            {{ contragent.adressjur }}
                        </div>
                        <div class="form-label">Юридический адрес</div>
                    </div>
                    <div class="form-group">
                        <div class="form-element form-element--sm form-element--filled flex-wrap">
                            <div class="full-width" *ngFor="let adress of contragent.adressdelivery">
                                {{ adress }}
                            </div>
                        </div>
                        <div class="form-label">Адрес доставки</div>
                    </div>
                </div>
            </div>
            <div class="accordeon__footer t-a-center-on-sm t-a-right-on-lg">
                <button class="btn btn--text js-delete-user" type="button" (click)="confirmContragentDelete = contragent">
                    <svg class="svg-icon m-r-8 main-color" viewBox="0 0 1 1" width="18" height="18">
                        <use xlink:href="/assets/img/sprite.svg#trash-bin-lined"></use>
                    </svg><span>Удалить юридическое лицо</span>
                </button>
            </div>
        </div>
    </section>

    <div class="flex j-c-end-on-lg">
        <button class="btn btn--main btn--md m-r-8" type="button" (click)="showContragentForm()">
            <svg class="svg-icon m-r-8" viewBox="0 0 1 1" width="12" height="12">
                <use xlink:href="/assets/img/sprite.svg#plus"></use>
            </svg><span>Добавить юридическое лицо</span>
        </button>
    </div>

</div>


<!-- modal -->
<div>
<div class="modal-overlay is-active" *ngIf="contragentForm">
<div class="modal">
    <h3 class="modal__header">Добавление юридического лица</h3>
    <div class="modal__content">
        <form [formGroup]="contragentForm" (ngSubmit)="saveNewContragent()">
            <div class="flex flex-wrap">
                <div class="form-group form-group--1x2">
                    <input class="form-element" id="name-legal-entity" type="text" formControlName="namejur"/>
                    <label class="form-label" for="name-legal-entity">
                        Название юр. лица<span class="danger-color">*</span>
                        <span class="err-msg err-msg--sm">{{ contragentForm.controls.namejur['errorMessage'] }}</span>
                    </label>
                </div>
                <div class="form-group form-group--1x2">
                    <input class="form-element" id="number-legal-entity" type="text" formControlName="inn"/>
                    <label class="form-label" for="number-legal-entity">
                        ИНН<span class="danger-color">*</span>
                        <span class="err-msg">{{ contragentForm.controls.inn['errorMessage'] }}</span>
                    </label>
                </div>
                <div class="form-group">
                    <textarea class="form-element" id="message-legal-entity" rows="1" formControlName="adressjur"></textarea>
                    <label class="form-label" for="message-legal-entity">
                        Юридический адрес
                        <span class="err-msg">{{ contragentForm.controls.adressjur['errorMessage'] }}</span>
                    </label>
                </div>
                <div class="form-group">
                    <textarea class="form-element" id="message-legal-entity" formControlName="adressdelivery" rows="3"></textarea>
                    <label class="form-label" for="message-legal-entity">
                        Адрес доставки <small>(можно ввести неск. адресов с новой строки)</small>
                        <span class="err-msg">{{ contragentForm.controls.adressdelivery['errorMessage'] }}</span>
                    </label>
                </div>
            </div>
            <div class="flex j-c-center">
                <button class="btn btn--main btn--lg" type="submit">Добавить юридическое лицо</button>
            </div>
        </form>
    </div>
    <button class="modal__close" type="button" (click)="contragentForm = null">
        <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
            <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
        </svg>
    </button>
</div>
</div>
</div>

<!-- modal -->
<div>
<div class="modal-overlay is-active" *ngIf="confirmContragentDelete">
    <div class="modal">
        <h3 class="modal__header">Вы уверены, что хотите удалить юр.лицо?</h3>
        <div class="modal__content">
            <p>Доступ к {{ confirmContragentDelete.namejur }} будет утерян. Подтвердите удаление:</p>
            <p>&nbsp;</p>
            <div class="t-a-center">
            <button class="btn btn--main" type="button" (click)="deleteContragent()">Да - удалить</button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn--outlined" type="button" (click)="confirmContragentDelete = null">Отмена</button>
            </div>
        </div>
        <button class="modal__close" type="button" (click)="confirmContragentDelete = null">
            <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
            </svg>
        </button>
    </div>
</div>
</div>

<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TAB 3 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<form [formGroup]="editPasswordForm" (ngSubmit)="updatePassword()" *ngIf="tab == 3">
    <div class="box">
        <div class="text-level-3">Изменить пароль</div>
        <div class="flex flex-wrap">
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <input type="password" class="form-element form-element--sm" id="user-password-2" formControlName="password_old"/>
                <label class="form-label" for="user-password-2">
                    Старый пароль<span class="danger-color">*</span>
                    <span class="err-msg">{{ editPasswordForm.controls.password_old['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <input type="password" class="form-element form-element--sm" id="user-password-3" formControlName="password" appInputPassword />
                <label class="form-label" for="user-password-3">
                    Новый пароль<span class="danger-color">*</span>
                    <span class="err-msg">{{ editPasswordForm.controls.password['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group form-group--1x3 form-group--no-margin-bottom">
                <input type="password" class="form-element form-element--sm" id="user-password-4" formControlName="password_confirm" appInputPassword />
                <label class="form-label" for="user-password-4">
                    Подтверждения пароля<span class="danger-color">*</span>
                    <span class="err-msg">{{ editPasswordForm.controls.password_confirm['errorMessage'] }}</span>
                </label>
            </div>
        </div>
    </div>
    <div class="flex j-c-end m-t-12 m-b-12">
        <button class="btn btn--main btn--lg" type="submit">Сохранить изменения</button>
    </div>
</form>
