<div *ngIf="page == 'products'">
    <h1 class="text-level-1 text-level-1--no-margin">Мы изготавливаем гибкие вырубные ножи (штампы) из высокопрочной стали  с использованием современных обрабатывающих технологий.</h1>

    <div class="accent-section">
        <div class="accent-section__content">

            <div class="accent-section__text">
                <ul class="cheque-list">
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Строгие допуски к точности изготовления обеспечат вам максимальный срок службы, высокоскоростную работу и экономическую выгоду для любых типов вырубки &ndash; ротационной, полуротационной и плоской.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Сталь специализированная, европейский стандарт, используется ведущими производителями ножей. Точность высоты +/- 2 мкм.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Классическая обработка поверхности, а также лазерная закалка RLL для повышения тиражестойкости и работы по спецматериалам. Разные углы заточки для разных видов материалов. По желанию затачиваем угол от 60 до 120 градусов.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Все современные требования к вырубке: острые углы, пересечения, несколько высот кромки на одном ноже, перфорация, микроперфорация.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Изготовление &ndash; от 1 (&laquo;срочно&raquo;) до 2 суток. Все способы доставки, в зависимости от потребностей заказчика.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Тиражестойкость в зависимости от:<br>
                        - высекаемого материала и нанесенного изображения;<br>
                        - вашего оборудования / опыта работы на нем.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Вне зависимости от сложности (материал, толщина лайнера, наличие биговки или перфорации) предложим оптимальное решение по конфигурации и параметрам ножа.</span>
                    </li>
                    <li class="flex a-i-center">
                        <div class="cheque-mark"><svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                        </svg></div>
                        <span class="cheque-list__item">Вместе с вырубным штампом вы получаете образец вырубленного материала.</span>
                    </li>
                </ul>
            </div>
            <div class="accent-section__img"><img src="/assets/img/justcut_i1.jpg" alt="Штамп"></div>
        </div>
    </div>

</div>

<div class="content--md" *ngIf="page == 'benefits'">
    <div class="flex flex-wrap j-c-between a-i-center a-i-start">
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Пересечение режущих кромок</div>
            <div class="benefit__descr">В наших силах сделать нож, когда одна режущая кромка пересекает другую. И не один раз. Главное при этом не забыть, что пересечение происходит под «острым» углом, то есть не имеет скругления. Значит и цена такого ножа выше. Но если очень надо и иначе никак – сделаем.</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Режущие кромки разной высоты</div>
            <div class="benefit__descr">Одна кромка ножа высечет вам этикетку, а другая кромка сделает сквозную перфорацию вместе с подложкой! И все довольны. Бывает еще масса разновысотных высечек. Высечь и нарезать на листы, например. Как понадобится – спрашивайте – решим!</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Перфорация, микроперфорация</div>
            <div class="benefit__descr">0,5 мм режущий элемент и такой же пробел это граница между перфорацией и микроперфорацией. Больше – перфорация. Меньше – микро. Выбирайте! А хотите – будем не резать, а мять. Биговать, значит. Тоже можно.</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Острые углы</div>
            <div class="benefit__descr">Невозможно скруглить угол радиусом 0,6 мм и выше? Заказчик настаивает или по-другому нельзя – что ж, придется делать. Острые углы – это возможно. Дольше, дороже. Но возможно!</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Бесконечный нож</div>
            <div class="benefit__descr">Поставили такой нож, режущие кромки на стыке совместились – вот прямая дорога катать билеты, посадочные талоны и т.п. С перфорацией, само-собой!</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Фигурная подрезка</div>
            <div class="benefit__descr">Для технологичности ножа (в частности, для избежания большого количества одновременных поперечных резов) ручьи сдвигают друг относительно друга. И края ножа на стыке будут «лесенкой». Можем посмотреть на ваш нож с точки зрения его технологичности (правильного расположения элементов) и предложим решение.</div>
        </div>
    </div>
    <div class="info-block m-b-32">
        <div class="info-block__caption">Высокая точность изготовления</div>
        <div class="info-block__text">Точность высоты – главное для ножа. Не менее главное – стабильность высоты. Наше оборудование может ловить и доли микрона, но для абсолютной уверенности заказчиков мы гарантируем +/- 2 микрона. Можем показать, как мы это делаем.</div>
    </div>
    <p>&nbsp;</p>
</div>

<div class="content--md" *ngIf="page == 'quality-service'">
    <div class="flex flex-wrap j-c-between a-i-center a-i-start">
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Производство</div>
            <div class="benefit__descr">Парк оборудования — это лучшие высокоточные станки, процессоры и программное обеспечение. Вся продукция изготавливается из высокопрочной стали от проверенных поставщиков.</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Гарантия качества</div>
            <div class="benefit__descr">100% продукции проходит проверку качества. Вы получаете образец вырубленного материала вместе с изготовленным вырубным штампом.</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Быстрая доставка</div>
            <div class="benefit__descr">Делаем доставку любым перевозчиком. Подбираем индивидуальные способы.</div>
        </div>
        <div class="benefit">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="19" height="19">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <div class="benefit__caption">Оперативная техподдержка</div>
            <div class="benefit__descr">Мы готовы ответить на ваши вопросы 25/8.</div>
        </div>
    </div>
    <div class="info-block">
        <div class="info-block__caption">Поставки запчастей и оборудования</div>
        <div class="info-block__text">Мы дружим с ведущими европейскими производителями оснастки.<br> Поэтому, для вашего производства с удовольствием поставим валы: магнитные, формные, противодавления, для поперечной резки. Пневмоцилиндры, системы продольной резки, анилоксовые валы. Шестерни. Высечные секции, манометры. Системы управления GAP. Поставки из Европы.</div>
    </div>




</div>

<div class="content" *ngIf="page == 'useful'">
    <p class="text-level-2 text-level-2--no-margin">О цене</p>
    <p>Нож &ndash; это то, что режет. Режет лезвие (у нас с вами кромка). Кромку точат. Чем длиннее кромка, тем дольше ее точат. Тем дороже нож.</p>
    <p>Итак, правило общее и первое: больше кромки &ndash; дороже нож.</p>
    <p>Понятно, что есть ножи, у которых кромки-то &ndash; раз-два и обчелся!.. То есть один кружочек диаметром 20 мм или 2 кружочка 20 мм &ndash; это одинаково мало. Поэтому существует минимальная цена для ножа, которая остается постоянной, даже если вы меняете количество или размеры режущей кромки.</p>
    <p>Дальше &ndash; сложнее. Не сильно, но уже не так просто. Длиннее кромка &ndash; нож дороже. Чуть больше удорожает нож не только длина кромки, а количество отдельных элементов. То есть, два прямоугольника 100х100 мм (длина кромки 800 мм у обоих) будут стоить дороже, чем один прямоугольник 200х200 мм (длина кромки те же 800 мм). А вот одна окружность длиной 800 мм и один прямоугольник с периметром 800 мм &ndash; одинаково. Все потому, что каждый новый элемент &ndash; это отдельная обработка для станка.</p>
    <p>Особую роль сыграет, если вы сделаете скругление углов радиусом меньше 0,6 мм. Все дело в движении фрезы. Она коническая, как вы понимаете, и при повороте она не даст на кромке прямой угол. Он будет скруглен до радиуса самой фрезы. А он как раз 0,6 мм.</p>
    <p>Хотите меньше &ndash; давайте запустим туда дополнительную обработку сверхтонкой фрезой, которая и работает медленнее (тратит время заточки), и вместо одного прохода начинает последовательно аккуратно выбирать скругленный старшей сестренкой угол. Долго. Дорого.</p>
    <p>&nbsp;</p>

    <p class="text-level-2 text-level-2--no-margin">Коэффициенты удорожания возникают, если:</p>
    <p>- радиус скругления переходов на кривой меньше 0,6 мм &ndash; &laquo;острые&raquo; углы;</p>
    <p>- есть пересечение или стык режущих кромок; одно пересечение &ndash; это сразу 4 &laquo;острых&raquo; угла;</p>
    <p>- витиеватые сложные контуры, которые сделаны дизайнером без учета специфики вырубки; их приходится долго дополнительно приводить к стандартам, обеспечивая технологичность и максимальную долговечность ножа;</p>
    <p>- двух и более уровневые ножи, когда присутствуют разновысотные элементы.</p>
    <p>Опытные реалисты приведут еще массу тонкостей по ценообразованию. Проектируя, например, количество ручьев для этикетки под учет материала в наличии, можно проиграть в стоимости, заказав более дорогой нож (4 ручья вместо двух, например). Но выиграть в скорости печати тиража. Но потом при печати повторов или заказывать широкий материал, или рубить только частью ножа. И т.д. Поэтому честь и хвала ОДП в неформальном союзе с технологами и менеджерами, которые молодцы!</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</div>
