<div>
    <div class="flex flex-wrap j-c-between">
        <div class="contact-item">
            <svg class="svg-icon" viewBox="0 0 1 1" width="32" height="32">
                <use xlink:href="/assets/img/sprite.svg#phone-two-ribbon"></use>
            </svg>
            <div class="contact-item__caption">телефон</div>
            <div class="contact-item__descr">
                <a href="tel:+{{ config.phoneOnlyDigits }}">{{ config.phone }}</a>
            </div>
        </div>
        <div class="contact-item">
            <svg class="svg-icon" viewBox="0 0 1 1" width="32" height="32">
                <use xlink:href="/assets/img/sprite.svg#placemark"></use>
            </svg>
            <div class="contact-item__caption">Наш адрес</div>
            <div class="contact-item__descr">г. Москва, ул. Авиамоторная, 55, к. 8 (проходная)</div>
        </div>
        <div class="contact-item">
            <svg class="svg-icon" viewBox="0 0 1 1" width="32" height="32">
                <use xlink:href="/assets/img/sprite.svg#envelope"></use>
            </svg>
            <div class="contact-item__caption">E-mail</div>
            <div class="contact-item__descr">
                <a href="mailto:{{ 'email' | env }}">{{ 'email' | env }}</a>
            </div>
        </div>
    </div>
    <div class="flex j-c-center"><a class="btn btn--main" href="https://yandex.ru/maps/213/moscow/?ll=37.727948%2C55.743764&mode=search&oid=238402797131&ol=biz&utm_source=main_stripe_big&z=14.37" target="_blank">Показать на карте</a>
    </div>
    <div class="light-section">
        <h2 class="text-level-1 t-a-center">Написать нам</h2>

        <form [formGroup]="contactForm" (ngSubmit)="send()" *ngIf="!success">
            <div class="flex flex-wrap">
                <div class="form-group form-group--1x4">
                    <input class="form-element form-element--filled" id="name-contacts" type="text" formControlName="first_name" />
                    <label class="form-label" for="name-contacts">
                        Имя<span class="danger-color">*</span>
                        <span class="err-msg">{{ f.first_name['errorMessage'] }}</span>
                    </label>
                </div>
                <div class="form-group form-group--1x4">
                    <input class="form-element form-element--filled" id="surname-contacts" type="text" formControlName="last_name" />
                    <label class="form-label" for="surname-contacts">Фамилия</label>
                </div>
                <div class="form-group form-group--1x4">
                    <input class="form-element form-element--filled" id="email-contacts" type="email" formControlName="email" />
                    <label class="form-label" for="email-contacts">
                        E-mail<span class="danger-color">*</span>
                        <span class="err-msg">{{ f.email['errorMessage'] }}</span>
                    </label>
                </div>
                <div class="form-group form-group--1x4">
                    <input class="form-element form-element--filled" id="tel-contacts" type="tel" formControlName="telefon" appInputPhone />
                    <label class="form-label" for="tel-contacts">Номер телефона</label>
                </div>
                <div class="form-group">
                    <input class="form-element form-element--filled" id="message-contacts" type="text"  formControlName="message" />
                    <label class="form-label" for="message-contacts">
                        Ваше сообщение<span class="danger-color">*</span>
                        <span class="err-msg">{{ f.message['errorMessage'] }}</span>
                    </label>
                </div>
                <div class="form-group">
                    <div class="checkbox">
                        <input class="checkbox__input" id="agreement-write-to-us" type="checkbox" value="1" formControlName="agreement" />
                        <label class="checkbox__label" for="agreement-write-to-us">
                            <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                            </svg>
                        </label>
                        <label class="checkbox__caption" for="agreement-write-to-us">
                            Согласие на обработку персональных данных
                            <span class="danger-color">*</span>
                            <span class="err-msg">{{ f.agreement['errorMessage'] }}</span>
                        </label>
                    </div>
                    <div class="full-width">
                        <a (click)="showPrivacyPolicy = true" class="read-privacy-text---link">Пользовательское соглашение ООО ДжастКат</a>
                    </div>
                </div>
            </div>
            <div class="flex j-c-center">
                <button class="btn btn--main btn--lg" type="submit">Отправить собщение</button>
            </div>
        </form>

        <div *ngIf="success" class="main-color t-a-center">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <p>Сообщение успешно отправлено!</p>
        </div>
    </div>
</div>

<app-privacy-text *ngIf="showPrivacyPolicy" (close)="showPrivacyPolicy = false"></app-privacy-text>
