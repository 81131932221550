import {compare, email, mask, maxLength, minLength, password, pattern, prop, required, whitelist} from '@rxweb/reactive-form-validators';
import {Contragent} from './contragent';

export class User {
    @required()
    @minLength({value: 3})
    first_name: string;

    @required()
    @minLength({value: 3})
    last_name: string;

    @prop()
    typography = '';

    @required()
    @email()
    email: string;


    @required()
    @whitelist('0-9+()-')
    @maxLength({value: 30})
    telefon: string;

    contragents: Contragent[];
    contragentsById: Record<Contragent['uidcontragent1c'], Contragent>;

    token: string;

    @prop() deploymentpreorder = 1;
    @prop() @email() deploymentpreordermail = '';

    @prop() deploymentorder = 1;
    @prop() @email() deploymentordermail = '';

    @prop() startorderinproduction = 1;
    @prop() @email() startorderinproductionmail = '';

    @prop() orderforshipment = 1;
    @prop() @email() orderforshipmentmail = '';

    @prop() courierorder = 1;
    @prop() @email() courierordermail = '';

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}
