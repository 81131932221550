import {Component, HostBinding, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormGroupExtension, ReactiveFormConfig, RxFormBuilder} from '@rxweb/reactive-form-validators';
import {Message} from '../../../models/message';
import {AlertService} from 'ngx-alerts';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'app-contacts-page',
  templateUrl: './contacts-page.component.html',
  styleUrls: ['./contacts-page.component.css']
})
export class ContactsPageComponent implements OnInit {
    @HostBinding('class') classes = 'content';

    contactForm: FormGroup;
    success: boolean;
    showPrivacyPolicy = false;

    constructor(
        private formBuilder: RxFormBuilder,
        private alert: AlertService,
        private http: HttpClient,
        public config: ConfigService
    ) { }

    ngOnInit(): void {
        this.contactForm = this.formBuilder.formGroup(new Message());
    }

    get rxForm(): FormGroupExtension{
        return this.contactForm as FormGroupExtension;
    }

    get f() {
        return this.rxForm.controls;
    }

    get v() {
        return this.rxForm.value;
    }

    send() {
        if (this.rxForm.invalid) {
            this.rxForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return false;
        }

        this.http.post('/contact', this.contactForm.value).subscribe(resp => {
            this.success = true;
        });
    }

}
