import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/user';
import {AlertService} from 'ngx-alerts';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private auth: AuthService,
      private alert: AlertService
  )  { }

  ngOnInit(): void {
      this.auth.activate(
          this.route.snapshot.params.user_id,
          this.route.snapshot.params.code
      ).subscribe(
          (user: User) => {
              this.router.navigate(['/calculator']);
              this.alert.success('Вы успешно активировали аккаунт, теперь можете начать работу с ЛК!');
          },
          () => {
              this.router.navigate(['/login']);
          }
      );
  }

}
