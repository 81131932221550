<app-whoops [whoopsMsg]="whoopsMsg"></app-whoops>

<div *ngIf="!rows && !whoopsMsg">{{ config.defaultMsg }}</div>

<div class="tabs-nav tabs-top-pos" id="tabs" *ngIf="rows">
    <a class="tabs-nav__btn" [class.tabs-nav__btn--active]="tab == 1" (click)="openOrders()">Заказы</a>
    <a class="tabs-nav__btn" [class.tabs-nav__btn--active]="tab == 2" (click)="openCalculations()">Расчеты</a>
</div>

<div class="table" *ngIf="rows">

    <div class="table__header" [class.table__header--shown]="mobTableHeaderShow">
        <div class="table__header-left hidden-on-table-sm">
            <ng-container [ngTemplateOutlet]="paging"></ng-container>
        </div>


        <!-- right -->
        <div class="inline-flex a-i-center m-l-auto-on-lg full-width-on-sm">

            <div class="search">
                <input class="search__field" placeholder="Поиск по..."  [(ngModel)]="search" (input)="searchFor($event.target.value)" />
                <span class="search__submit" type="submit">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="17" height="17">
                        <use xlink:href="/assets/img/sprite.svg#lens"></use>
                    </svg>
                </span>
            </div>

            <button class="btn btn--main btn--md f-w-700 m-l-16 hidden-on-sm" type="button" routerLink="/calculator">
                <svg class="svg-icon" viewBox="0 0 1 1" width="14" height="14">
                    <use xlink:href="/assets/img/sprite.svg#plus"></use>
                </svg>
                <span class="hidden-below-xlg m-l-8">Новый {{ shownOrdersType == 1 ? 'заказ' : 'расчет' }}</span>
            </button>

        </div>

    </div>


    <div class="table__nav" *ngIf="tab == 1">
        <div class="table__cell table__cell--order nameorder">№ инструмента</div>
        <div class="table__cell table__cell--order anglesharpening">Угол</div>
        <div class="table__cell table__cell--order laserhardening">RLL</div>
        <div class="table__cell table__cell--order antiadhesioncoating">RAA</div>
        <div class="table__cell table__cell--order typecircuit">Контур вырубки</div>
        <div class="table__cell table__cell--order shippingdate">План отгрузки</div>
        <div class="table__cell table__cell--order statusorder">Статус</div>
        <div class="table__cell table__cell--order dateorder">Дата заказа</div>
        <div class="table__cell table__cell--order orderid">№ заказа JC</div>
        <div class="table__cell table__cell--order sumorder">Цена</div>
        <div class="table__cell table__cell--order knifemileagetotal">Пробег</div>
    </div>

    <div class="table__nav table__nav--calc" *ngIf="tab == 2">
        <div class="table__cell table__cell--calc orderid">№ расчета JC</div>
        <div class="table__cell table__cell--calc nameorder">№ инструмента</div>
        <div class="table__cell table__cell--calc dateorder">Дата расчета</div>
        <div class="table__cell table__cell--calc typecircuit">Контур вырубки</div>
        <div class="table__cell table__cell--calc width mm-after">Ширина</div>
        <div class="table__cell table__cell--calc length mm-after">Длина</div>
        <div class="table__cell table__cell--calc diameter mm-after">Диаметр</div>
        <div class="table__cell table__cell--calc amountstreams">Ручьи</div>
        <div class="table__cell table__cell--calc amountrepetitionsrapport">В ручье</div>
        <div class="table__cell table__cell--calc anglesharpening">Угол</div>
        <div class="table__cell table__cell--calc laserhardening">RLL</div>
        <div class="table__cell table__cell--calc antiadhesioncoating">RAA</div>
        <div class="table__cell table__cell--calc sumorder">Цена</div>
    </div>


    <div class="table__content" [class.table__content--calc]="tab == 2">

        <div class="table__row" *ngFor="let order of rows | paginate: config"
             [class.table__row--shown]="order.shown"
             [class.table__row--accent]="order.rushorder"
             (click)="order.shown = !order.shown;">

            <div class="table__row-header" *ngIf="shownOrdersType == 1">
                <div class="table__cell table__cell--order nameorder">
                    <span class="cell_name">№ инструмента</span>
                    {{ order.nameorder || 'не указан' }}
                </div>

                <div class="table__cell table__cell--order anglesharpening">
                    <span class="cell_name">Угол</span>
                    {{ order.anglesharpening }}°
                </div>
                <div class="table__cell table__cell--order laserhardening">
                    <span class="cell_name">RLL</span>
                    <svg *ngIf="order.laserhardening" class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                        <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                    </svg>
                </div>
                <div class="table__cell table__cell--order antiadhesioncoating">
                    <span class="cell_name">RAA</span>
                    <svg *ngIf="order.antiadhesioncoating" class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                        <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                    </svg>
                </div>


                <div class="table__cell table__cell--order typecircuit">
                    <span class="cell_name">Контур вырубки</span>
                    <img class='m-r-4' src='assets/img/{{ circuitTypes.icon[order.typecircuit] }}-contour.svg' alt='typecircuit-icon'>
                    {{ circuitTypes.name[order.typecircuit] }}
                </div>
                <div class="table__cell table__cell--order shippingdate">
                    <span class="cell_name">План отгрузки</span>
                    {{ order.shippingdate | asdate }}
                </div>
                <div class="table__cell table__cell--order statusorder">
                    <span class="cell_name">Статус</span>
                    <div class="status" [ngClass]="statusClass[order.statusorder.toLowerCase()] || 'status--on-hold'">
                        <span>{{ order.statusorder }}</span>
                    </div>
                </div>
                <div class="table__cell table__cell--order dateorder" [title]="order.dateorder">
                    <span class="cell_name">Дата заказа</span>
                    {{ order.dateorder | asdate }}
                </div>
                <div class="table__cell table__cell--order orderid f-w-700">
                    <span class="cell_name">№ заказа JC</span>
                    {{ order.orderid }}
                </div>
                <div class="table__cell table__cell--order sumorder f-s-20">
                    <span class="cell_name">Цена</span>
                    {{ order.sumorder }} ₽
                </div>
                <div class="table__cell table__cell--order knifemileagetotal">
                    <span class="cell_name">Пробег</span>
                    <div *ngIf="order.knifemileagetotal">
                        {{ order.knifemileagetotal }} км <br>
                        {{ order.knifeturntotal }} об.
                    </div>
                </div>
            </div>

            <div class="table__row-header" *ngIf="shownOrdersType == 0">
                <div class="table__cell table__cell--calc orderid">
                    <span class="cell_name">№ расчета JC</span>
                    <b>{{ order.orderid }}</b>
                </div>
                <div class="table__cell table__cell--calc nameorder">
                    <span class="cell_name">№ инструмента</span>
                    {{ order.nameorder || 'не указан' }}
                </div>
                <div class="table__cell table__cell--calc dateorder" [title]="order.dateorder">
                    <span class="cell_name">Дата расчета</span>
                    {{ order.dateorder | asdate }}
                </div>
                <div class="table__cell table__cell--calc typecircuit">
                    <span class="cell_name">Контур вырубки</span>
                    <img class='m-r-4' src='assets/img/{{ circuitTypes.icon[order.typecircuit] }}-contour.svg' alt='typecircuit-icon'>
                    {{ circuitTypes.name[order.typecircuit] }}
                </div>
                <div class="table__cell table__cell--calc width">
                    <span class="cell_name">Ширина</span>
                    <span *ngIf="order.width">{{ order.width }}</span>
                </div>
                <div class="table__cell table__cell--calc length">
                    <span class="cell_name">Длина</span>
                    <span *ngIf="order.length">{{ order.length }}</span>
                </div>
                <div class="table__cell table__cell--calc diameter">
                    <span class="cell_name">Диаметр</span>
                    <span *ngIf="order.diameter">{{ order.diameter }}</span>
                </div>

                <div class="table__cell table__cell--calc amountstreams">
                    <span class="cell_name">Ручьи</span>
                    {{ order.amountstreams }}
                </div>
                <div class="table__cell table__cell--calc amountrepetitionsrapport">
                    <span class="cell_name">В ручье</span>
                    {{ order.amountrepetitionsrapport }}
                </div>
                <div class="table__cell table__cell--calc anglesharpening">
                    <span class="cell_name">Угол</span>
                    {{ order.anglesharpening }}°
                </div>
                <div class="table__cell table__cell--calc laserhardening">
                    <span class="cell_name">RLL</span>
                    <svg *ngIf="order.laserhardening" class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                        <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                    </svg>
                </div>
                <div class="table__cell table__cell--calc antiadhesioncoating">
                    <span class="cell_name">RAA</span>
                    <svg *ngIf="order.antiadhesioncoating" class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                        <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                    </svg>
                </div>
                <div class="table__cell table__cell--calc table__cell--sumorder sumorder f-s-20">
                    <span class="cell_name">Цена</span>
                    {{ order.sumorder }} ₽
                    <span *ngIf="order.rushorder" class="rushorder_mark">срочно</span>
                </div>
            </div>
            <!-- content -->
            <div class="table__row-content">
                <div class="table__order-tech-specs">
                    <div class="spec-table">
                        <div class="spec-table__group" *ngIf="order.typefelling">
                            <div class="spec-table__item" *ngIf="order.shaftpitch">
                                <div class="spec-table__caption">Раппорт м.вала:</div>
                                <div class="spec-table__text">{{ order.shaftpitch }} мм</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Тип вырубки:</div>
                                <div class="spec-table__text">{{ fellingTypes[order.typefelling] }}</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Угол заточки кромки:</div>
                                <div class="spec-table__text">{{ order.anglesharpening }}&deg;</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Высота ножа:</div>
                                <div class="spec-table__text">{{ order.knifeheight }} мкм</div>
                            </div>

                            <div class="spec-table__item">
                                <div class="spec-table__caption">Толщина подложки:</div>
                                <div class="spec-table__text">{{ order.substratethickness }} мкм</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Ширина ножа:</div>
                                <div class="spec-table__text">{{ order.knifewidth }} мм</div>
                            </div>
                            <div class="spec-table__item" *ngIf="order.typefelling == 2">
                                <div class="spec-table__caption">Длина ножа:</div>
                                <div class="spec-table__text">{{ order.knifelength }} мм</div>
                            </div>

                            <div class="spec-table__item">
                                <div class="spec-table__caption">RLL (ЛАЗЕРНАЯ ЗАКАЛКА):</div>
                                <div class="spec-table__text">
                                    {{ order.laserhardening ? 'да' : 'нет' }}<br>
                                </div>
                            </div>

                            <div class="spec-table__item">
                                <div class="spec-table__caption">RAA (Антиадгезионное покрытие):</div>
                                <div class="spec-table__text">
                                    {{ order.antiadhesioncoating ? 'да' : 'нет'  }}<br>
                                </div>
                            </div>

                        </div>
                        <div class="spec-table__group" *ngIf="order.typecircuit">
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Контур:</div>
                                <img class='m-r-4' src='assets/img/{{ circuitTypes.icon[order.typecircuit] }}-contour.svg' alt='typecircuit-icon'>
                                {{ circuitTypes.name[order.typecircuit] }}
                            </div>
                            <!--для прямоугольника-->
                            <div class="spec-table__item" *ngIf="order.width">
                                <div class="spec-table__caption">Ширина, L</div>
                                <div class="spec-table__text">{{ order.width }} мм</div>
                            </div>
                            <div class="spec-table__item" *ngIf="order.length">
                                <div class="spec-table__caption">Длина, B</div>
                                <div class="spec-table__text">{{ order.length }} мм</div>
                            </div>
                            <div class="spec-table__item" *ngIf="order.radiusfillet">
                                <div class="spec-table__caption">Радиус, R</div>
                                <div class="spec-table__text">{{ order.radiusfillet }} мм</div>
                            </div>
                            <!--для круга-->
                            <div class="spec-table__item" *ngIf="order.diameter">
                                <div class="spec-table__caption">Диаметр, D</div>
                                <div class="spec-table__text">{{ order.diameter }} мм</div>
                            </div>

                            <!--для свободного контура-->
                            <div *ngIf="order.typecircuit == 3">

                                <div class="spec-table__item">
                                    <div class="spec-table__caption">Несколько углов заточки</div>
                                    <div class="spec-table__text">{{ order.multipleanglessharpening ? 'да' : 'нет' }}</div>
                                </div>

                                <div class="spec-table__item">
                                    <div class="spec-table__caption">Несколько углов кромки</div>
                                    <div class="spec-table__text">{{ order.multipleedgeheights ? 'да' : 'нет' }}</div>
                                </div>

                                <div class="spec-table__item">
                                    <div class="spec-table__caption">Бесконечный нож</div>
                                    <div class="spec-table__text">{{ order.unlimknife ? 'да' : 'нет' }}</div>
                                </div>

                                <div class="spec-table__item">
                                    <div class="spec-table__caption">Перфорация</div>
                                    <div class="spec-table__text">
                                        {{ order.perforation ? 'да' : 'нет' }}
                                        <span class="spec-table__text" *ngIf="order.perforation">
                                            (высота перфорации: {{ order.perforationheight }} микрон)
                                        </span>
                                    </div>

                                </div>

                                <div class="spec-table__item">
                                    <div class="spec-table__caption">Микроперфорация</div>
                                    <div class="spec-table__text">
                                        {{ order.microperforation ? 'да' : 'нет' }}
                                        <span class="spec-table__text" *ngIf="order.microperforation">
                                            (количество резов: {{ order.qtycut }})
                                        </span>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div class="spec-table__group" *ngIf="order.amountstreams">
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Ручьев:</div>
                                <div class="spec-table__text">{{ order.amountstreams }}</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">В ручье:</div>
                                <div class="spec-table__text">{{ order.amountrepetitionsrapport }}</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Между ручьями, A1</div>
                                <div class="spec-table__text">{{ order.distancestreams }} мм</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Между повторениями, A2</div>
                                <div class="spec-table__text">{{ order.distancerepetitionsrapport }} мм</div>
                            </div>
                        </div>

                        <div class="spec-table__group">
                            <div class="spec-table__item" *ngIf="order.namecontragent1c">
                                <div class="spec-table__caption">Юридическое лицо:</div>
                                <div class="spec-table__text">{{ order.namecontragent1c }}</div>
                            </div>
                            <div class="spec-table__item">
                                <div class="spec-table__caption">Пользователь:</div>
                                <div class="spec-table__text">{{ order.namecreator }}</div>
                            </div>
                        </div>

                        <div class="spec-table__group"></div>
                    </div>

                    <div class="table__calc-elements" *ngIf="order.elements">
                        <div class="table-info" *ngFor="let el of order.elements; index as i">
                            <h4 class="table-info__caption"><b>Элемент №{{ i+1 }}</b></h4>
                            <div class="table-info__text">
                                <b>Периметр одного элемента:</b>&#32;{{ el.perimeter}} мм<br/>
                                <b>К-во повторений на ноже:</b>&#32;{{ el.amountrepetitionsrapport }}<br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table__order-docs" *ngIf="order.typeorder == 1 && order.statusorder.toLowerCase() == 'завершен'">

                    <a class="btn-link btn-link--pdf" (click)="getOrderKnifeturnHistory(order, $event)">
                        Скачать пробег
                    </a>

                    <div *ngIf="user.contragentsById[order.uidcontragent1c]?.knifehistoryandrecycling">
                        <button class="btn-link btn-link--production" type="button" (click)="showKnifeturnForm(order, $event)">
                            Добавить тираж
                        </button>

                        <a class="btn-link btn-link--knifere--cycle" (click)="setOrderKnifereCycle(order, $event)">
                            Утилизировать нож
                        </a>
                    </div>

                </div>
            </div>

            <div class="table__row-footer" *ngIf="order.typeorder == 1">
                <button class="table__row-more js-show-table-row" type="button">
                    <span class="more--text--expand">Показать подробности</span>
                    <span class="more--text--collapse">Скрыть подробности</span>
                </button>

                <div *ngIf="user.contragentsById[order.uidcontragent1c]?.orderinproduction">

                    <button *ngIf="['отменен', 'завершен'].includes(order.statusorder.toLowerCase()) == false"
                            class="table__row-delete js-del-table-row" type="button" title="Отменить заказ"
                            (click)="confirmOrderDelete = order; $event.stopPropagation();">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                            <use xlink:href="/assets/img/sprite.svg#cross-in-circle"></use>
                        </svg>
                    </button>

                    <a class="table__row-edit" title="Повторить заказ" (click)="repeatOrder(order)">
                        <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                            <use xlink:href="/assets/img/sprite.svg#repeat"></use>
                        </svg>
                    </a>

                </div>
            </div>

            <div class="table__row-footer" *ngIf="order.typeorder == 0">
                <button class="table__row-more js-show-table-row" type="button">
                    <span class="more--text--expand">Показать подробности</span>
                    <span class="more--text--collapse">Скрыть подробности</span>
                </button>

                <a class="table__row-edit" [routerLink]="['/calculator', order.uidorder1c]" title="Редактировать">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                        <use xlink:href="/assets/img/sprite.svg#pencil"></use>
                    </svg>
                </a>

                <button class="table__row-delete js-del-table-row" type="button" (click)="confirmOrderDelete = order; $event.stopPropagation();" title="Удалить расчет">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                        <use xlink:href="/assets/img/sprite.svg#cross-in-circle"></use>
                    </svg>
                </button>

                <div class="table__row-checkout">
                    <button class="btn btn--main" type="button" (click)="doCheckout(order)">
                        Оформить <span class="m-l-4 hidden-between-table-lg-and-xlg">заказ</span>
                    </button>
                </div>
            </div>

        </div>

        <div class="table__cell" *ngIf="config.totalItems == 0">
            <div class="table__row-header">{{ config.defaultMsg }}</div>
        </div>
    </div><!-- /table__content -->




<!--    <div class="table__footer">
        <ng-container [ngTemplateOutlet]="paging"></ng-container>

        <button class="btn btn&#45;&#45;round m-l-auto hidden-on-table-lg js-toggle-search" type="button" (click)="mobTableHeaderShow = !mobTableHeaderShow">
            <svg class="svg-icon" viewBox="0 0 1 1" width="14" height="14">
                <use xlink:href="/assets/img/sprite.svg#lens"></use>
            </svg>
        </button>

        <button class="btn btn&#45;&#45;main btn&#45;&#45;round m-l-8 hidden-on-table-lg" type="button" routerLink="/calculator">
            <svg class="svg-icon" viewBox="0 0 1 1" width="14" height="14">
                <use xlink:href="/assets/img/sprite.svg#plus"></use>
            </svg>
        </button>
    </div>-->


</div>




<!--  PAGINATION -->
<ng-template #paging>
    <div class="inline-flex a-i-center f-s-14 hidden-on-table-sm">
        <span class="m-r-8">Показаны</span>
        <b>{{ p.isLastPage() ? config.totalItems - (p.getCurrent()-1) * config.itemsPerPage : config.itemsPerPage }} из {{ config.totalItems }}</b>
        <span class="m-l-8">заказов</span>
    </div>

    <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPageChange($event)" class="pagination m-l-24 m-l-48-on-lg">

        <a class="pagination__item" [class.not-active]="p.isFirstPage()" (click)="p.previous()">
            <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                <use xlink:href="/assets/img/sprite.svg#angle-to-left-in-circle"></use>
            </svg>
        </a>

        <span class="pagination__item pagination__item--active" *ngIf="config.totalItems">
            {{ p.getCurrent() * config.itemsPerPage - config.itemsPerPage + 1 }} -
            {{ p.isLastPage() ? config.totalItems : p.getCurrent() * config.itemsPerPage }}
        </span>

        <span class="pagination__item pagination__item--active" *ngIf="config.totalItems == 0">...</span>

        <a class="pagination__item" [class.not-active]="p.isLastPage()" (click)="p.isLastPage() || p.next()">
            <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                <use xlink:href="/assets/img/sprite.svg#angle-to-right-in-circle"></use>
            </svg>
        </a>

    </pagination-template>

    <div class="inline-flex a-i-center f-s-14 m-l-24 m-l-48-on-lg">
        <span class="m-r-8 hidden-on-table-sm">Сортировать по</span>
        <div class="select select--btn f-s-12">
            <select class="select__inner" [(ngModel)]="sort" (ngModelChange)="sortBy($event)">
                <option value="dateorder">дате {{ shownOrdersType == 1 ? 'заказа' : 'расчета' }}</option>
                <option value="orderid">по номеру</option>
            </select>
        </div>
    </div>

</ng-template>


<div>
    <app-order-form
        *ngIf="checkoutOrder"
        [order]="checkoutOrder"
        (close)="checkoutOrder = null"
        (closeSuccessModal)="checkoutOrder = null;loadOrders()"
    ></app-order-form>

    <!-- modal -->
    <div class="modal-overlay is-active" *ngIf="confirmOrderDelete">
        <div class="modal">
            <h3 class="modal__header">
                Вы уверены, что хотите {{ confirmOrderDelete.typeorder ? 'отменить заказ' : 'удалить расчет'}}?
            </h3>
            <div class="modal__content">
                <p>
                    Подтвердите {{ confirmOrderDelete.typeorder ? 'отмену заказа' : 'удаление расчета'}}
                    № {{ confirmOrderDelete.orderid }}:</p>
                <p>&nbsp;</p>
                <div class="t-a-center">
                    <button class="btn btn--main" type="button" (click)="cancelOrder(confirmOrderDelete)">
                        Да - {{ confirmOrderDelete.typeorder ? 'отменить' : 'удалить'}}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button class="btn btn--outlined" type="button" (click)="confirmOrderDelete = null">Нет</button>
                </div>
            </div>
            <button class="modal__close" type="button" (click)="confirmOrderDelete = null">
                <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                    <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
                </svg>
            </button>
        </div>
    </div>

    <div class="modal-overlay is-active" *ngIf="knifeturnForm">
        <div class="modal">
            <h3 class="modal__header">Добавить тираж</h3>
            <div class="modal__content">
                <form [formGroup]="knifeturnForm" (ngSubmit)="setOrderKnifeturn()">
                    <input type="hidden" name="uidorder1c" formControlName="uidorder1c" />
                    <div class="flex flex-wrap">
                        <div class="form-group form-group--1x2">
                            <input class="form-element form-element--filled" id="knifeturn-date" type="datetime-local" step="1" formControlName="date" />
                            <label class="form-label" for="knifeturn-date">
                                Дата<span class="danger-color">*</span>
                            </label>
                        </div>
                        <div class="form-group form-group--1x2">
                            <input class="form-element" id="knifeturn-edition" type="text" formControlName="edition" />
                            <label class="form-label" for="knifeturn-edition">
                                Тираж<span class="danger-color">*</span>
                            </label>
                        </div>
                        <div class="form-group form-group--1x2">
                            <input class="form-element" id="knifeturn-printer" type="text" formControlName="printer"/>
                            <label class="form-label" for="knifeturn-printer">
                                Печатник
                            </label>
                        </div>
                        <div class="form-group form-group--1x2">
                            <input class="form-element" id="knifeturn-knifeturn" type="number" formControlName="knifemileage" />
                            <label class="form-label" for="knifeturn-knifeturn">
                                Пробег км<span class="danger-color">*</span>
                            </label>
                        </div>
                        <div class="form-group">
                            <textarea class="form-element" id="knifeturn-comment" rows="1" formControlName="comment"></textarea>
                            <label class="form-label" for="knifeturn-comment">
                                Примечание
                            </label>
                        </div>
                    </div>
                    <div class="flex j-c-center">
                        <button class="btn btn--main btn--lg" type="submit">Отправить</button>
                    </div>
                </form>
            </div>
            <button class="modal__close" type="button" (click)="knifeturnForm = null">
                <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                    <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
                </svg>
            </button>
        </div>
    </div>

</div>
