import {Component, HostBinding, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {FormGroupExtension, ReactiveFormConfig, RxFormBuilder} from '@rxweb/reactive-form-validators';
import {AlertService} from 'ngx-alerts';
import {NewUser} from '../../../models/new-user';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    @HostBinding('class') classes = 'content content--md a-s-center';
    registrationForm: FormGroup;
    success: boolean;
    showPrivacyPolicy = false;

    constructor(
        private auth: AuthService,
        private alert: AlertService,
        private formBuilder: RxFormBuilder
    ) { }

    ngOnInit() {
        this.registrationForm = this.formBuilder.formGroup(new NewUser());
    }

    get rxForm(): FormGroupExtension{
        return this.registrationForm as FormGroupExtension;
    }

    get f() {
        return this.rxForm.controls;
    }

    register() {
        if (this.rxForm.invalid) {
            this.rxForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.register(this.rxForm.value).subscribe(resp => {
            this.success = true;
            this.auth.sendActivationCode(this.rxForm.value.email).subscribe();
        });
    }

}
