import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
    private settings = {
        phone: ''
    };

    constructor(private http: HttpClient) { }

    load() {
        console.log('loading config');

        return this.http.get('/settings').toPromise()
            .catch(error => {
                return {phone: ''};
            })
            .then((settings: any) => {
                this.settings = settings;
            });
    }

    get(key) {
        return this.settings[key] || '';
    }

    get phone(): string {
        return this.settings.phone;
    }

    get phoneOnlyDigits(): string {
        return this.settings.phone.replace(/[^0-9]/g, '');
    }
}
