import {Injectable} from '@angular/core';
import {Order} from '../../../models/order';
import {OrderService} from '../../../services/order.service';

@Injectable({
    providedIn: 'root'
})
export class KnifePreviewService {

    public error;

    constructor(private orderService: OrderService) { }

    doCalculate(paramCalc: Order, area: {width: number, height: number}) {
        let result: any;
        this.error = 'Введите все данные для построения прототипа';

        switch (paramCalc.typecircuit) {
            case 1:
                return this.doCalculateCircle(paramCalc, area);
            case 2:
                return this.doCalculateSquare(paramCalc, area);
            default:
                this.error = 'Для данного контура построение прототипа не предусмотренно';
        }
    }

    doCalculateCircle(paramCalc: Order, area: {width: number, height: number}) {
        let widthtechfields = this.orderService.getWidthTechFields();

        let shaftpitch = paramCalc.shaftpitch;
        let diameter = paramCalc.diameter;
        let amountrepetitionsrapport = paramCalc.amountrepetitionsrapport;
        let amountstreams = paramCalc.amountstreams;
        let distancestreams = paramCalc.distancestreams;
        let knifewidth = paramCalc.knifewidth;
        let calculationenabled = 1;
        let distancerepetitionsrapport = paramCalc.distancerepetitionsrapport;

        if (!calculationenabled || !shaftpitch || !diameter || !amountrepetitionsrapport) {
            return;
        }

        let repetitions_distance;

        if (distancerepetitionsrapport) {
            repetitions_distance = distancerepetitionsrapport;
        } else {
            repetitions_distance = (shaftpitch - diameter * amountrepetitionsrapport) / amountrepetitionsrapport;
        }

        repetitions_distance = repetitions_distance.toFixed(2);

        if (!(amountrepetitionsrapport == 1) && repetitions_distance < 0.6) {
            return;
        }

        if (!amountstreams || (!(amountstreams == 1) && !distancestreams)) {
            return;
        }

        let elem_perimeter = diameter * Math.PI;
        let elem_count = amountrepetitionsrapport * amountstreams;

        //let knifewidth = (diameter + distancestreams) * amountstreams + 20 - distancestreams;
        if (!knifewidth) {
            let widthtechfields = this.orderService.getWidthTechFields();
            knifewidth = (diameter + distancestreams) * amountstreams + widthtechfields - distancestreams;
        }

        let total_perimeter = elem_perimeter * elem_count;

        if (distancerepetitionsrapport) {
            if (knifewidth > 800) {
                this.error = 'Ширина ножа превышает допустимый размер 800 мм';
                return;
            }
        } else {
            if (knifewidth > 800 || shaftpitch > 800) {
                this.error = 'Общие размеры вырубного штампа не могут превышать 800*800 мм';
                return;
            }
        }

        let block_width = area.width;
        let block_height = area.height;

        let elementsWidthSum = diameter * amountstreams;
        let elementsDistanceSum = distancestreams * amountstreams;
        let real_width = elementsWidthSum + elementsDistanceSum;

        let real_height = diameter * amountrepetitionsrapport + repetitions_distance * (amountrepetitionsrapport - 1);

        let rate = block_width / real_width;

        if (real_height * rate > block_height) {
            rate = block_height / real_height;
        }

        let row_margin_side = distancestreams * rate / 2;
        let row_margin_bottom = repetitions_distance * rate;
        let item_width = diameter * rate;
        let item_height = diameter * rate;
        let item_radius = diameter * rate / 2;

        let result = [];

        for (let k = 0; k < amountrepetitionsrapport; k++) {
            //'<div class="row" style="margin-left:-' + row_margin_side + 'px;margin-right:-' + row_margin_side + 'px;margin-bottom:' + row_margin_bottom + 'px;">';

            let items = [];

            for (let i = 0; i < amountstreams; i++) {
                items.push({'margin': '0 '+row_margin_side+'px', 'width.px': item_width, 'height.px': item_height, 'border-radius.px': item_radius});
                //'<div class="itm" style="margin-left:' + row_margin_side + 'px;margin-right:' + row_margin_side + 'px;width:' + item_width + 'px;height:' + item_height + 'px;border-radius:' + item_radius + 'px;"></div>';
            }

            result.push({
                style: {'margin-bottom': row_margin_bottom+'px'},
                items: items
            });
            //style: {'margin': '0 '+row_margin_side+'px '+row_margin_bottom+'px'},
        }

        return result;
    }

    doCalculateSquare(paramCalc: Order, area: {width: number, height: number}) {
        let shaftpitch = paramCalc.shaftpitch;
        let width = paramCalc.width;
        let height = paramCalc.length;
        let radius = paramCalc.radiusfillet;
        let amountrepetitionsrapport = paramCalc.amountrepetitionsrapport;
        let amountstreams = paramCalc.amountstreams;
        let distancestreams = paramCalc.distancestreams;
        let knifewidth = paramCalc.knifewidth;
        let calculationenabled = 1;
        let distancerepetitionsrapport = paramCalc.distancerepetitionsrapport;

        if (!calculationenabled || !shaftpitch || !height || !width || !amountrepetitionsrapport || (radius > width / 2) || (radius > height / 2)) {
            return;
        }

        let repetitions_distance;

        if (distancerepetitionsrapport) {
            repetitions_distance = distancerepetitionsrapport;
        } else {
            repetitions_distance = (shaftpitch - height * amountrepetitionsrapport) / amountrepetitionsrapport;
        }

        repetitions_distance = repetitions_distance.toFixed(2);

        if (!(amountrepetitionsrapport == 1) && repetitions_distance < 0.6) {
            return;
        }

        if (!amountstreams || (!(amountstreams == 1) && !distancestreams)) {
            return;
        }

        let elem_perimeter = (width + height) * 2;
        let elem_count = amountrepetitionsrapport * amountstreams;
        //let knifewidth = (width + distancestreams) * amountstreams + 20 - distancestreams;

        if (!knifewidth) {
            let widthtechfields = this.orderService.getWidthTechFields();
            knifewidth = (width + distancestreams) * amountstreams + widthtechfields - distancestreams;
        }

        let total_perimeter = elem_perimeter * elem_count;

        if (distancerepetitionsrapport) {
            if (knifewidth > 800) {
                this.error = 'Ширина ножа превышает допустимый размер 800 мм';
                return;
            }
        } else {
            if (knifewidth > 800 || shaftpitch > 800) {
                this.error =  'Общие размеры вырубного штампа не могут превышать 800*800 мм';
                return;
            }
        }

        let block_width = area.width;
        let block_height = area.height;

        let elementsWidthSum = width * amountstreams;
        let elementsDistanceSum = distancestreams * amountstreams;
        let real_width = elementsWidthSum + elementsDistanceSum;

        let real_height = height * amountrepetitionsrapport + repetitions_distance * (amountrepetitionsrapport - 1);

        let rate = block_width / real_width;

        if (real_height * rate > block_height) {
            rate = block_height / real_height;
        }

        let row_margin_side = distancestreams * rate / 2;
        let row_margin_bottom = repetitions_distance * rate;
        let item_width = width * rate;
        let item_height = height * rate;
        let item_radius = radius * rate;

        let result = [];

        for (let k = 0; k < amountrepetitionsrapport; k++) {
            //'<div class="row" style="margin-left:-' + row_margin_side + 'px;margin-right:-' + row_margin_side + 'px;margin-bottom:' + row_margin_bottom + 'px;">';

            let items = [];

            for (let i = 0; i < amountstreams; i++) {
                items.push({'margin': '0 '+row_margin_side+'px', 'width.px': item_width, 'height.px': item_height, 'border-radius.px': item_radius});
                //'<div class="itm" style="margin-left:' + row_margin_side + 'px;margin-right:' + row_margin_side + 'px;width:' + item_width + 'px;height:' + item_height + 'px;border-radius:' + item_radius + 'px;"></div>';
            }

            result.push({
                style: {'margin-bottom': row_margin_bottom+'px'},
                items: items
            });
        }

        return result;
    }
}
