import {User} from './user';
import {compare, password, prop, required, requiredTrue} from '@rxweb/reactive-form-validators';

export class NewUser extends User {
    @required()
    @password({validation: { maxLength: 15, minLength: 5} })
    password: string;

    @required()
    @compare({fieldName: 'password'})
    password_confirm: string;

    @prop()
    @requiredTrue({ message: 'Пожалуйста, дайте согласие на обработку данных' })
    agreement: boolean;
}
