import {Component, HostBinding, OnInit} from '@angular/core';
import {Form, FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {FormGroupExtension, ReactiveFormConfig, RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {User} from '../../../models/user';
import {AlertService} from 'ngx-alerts';
import {Contragent} from '../../../models/contragent';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-user-area.content',
  templateUrl: './user-area.component.html',
  styleUrls: ['./user-area.component.css']
})
export class UserAreaComponent implements OnInit {
    @HostBinding('class') classes = 'content content--lg m-b-64 a-s-center';
    tab = 1;
    profileForm: FormGroup;
    user: User = new User();
    contragentActive: string;
    contragentForm: FormGroup;
    editPasswordForm: FormGroup;
    confirmContragentDelete: Contragent;
    whoopsMsg: string;
    config = {
        defaultMsg: 'Подождите, идет загрузка данных пользователя ...'
    };

    constructor(
        private auth: AuthService,
        private alert: AlertService,
        private formBuilder: RxFormBuilder
    ) { }

    ngOnInit() {
        this.auth.getProfileData().subscribe(
            (user: User) => {
                this.user = user;
                this.profileForm = this.formBuilder.formGroup(new User(user));
            },
            this.catchError.bind(this)
        );
    }

    catchError(err: HttpErrorResponse) {
        if (err.error && err.error.errors) {
            let errorOne = err.error.errors[0];
            this.whoopsMsg = errorOne.details || errorOne.message;
        }
    }

    get rxForm(): FormGroupExtension{
        return this.profileForm as FormGroupExtension;
    }

    get f() {
        return this.rxForm.controls;
    }

    updateProfile() {
        if (this.rxForm.invalid) {
            this.rxForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.updateProfileData(this.rxForm.value).subscribe(resp => {
            this.alert.success('Ваши данные успешно сохранены!');
        });
    }

    showContragentDetails(uidcontragent1c) {
        this.contragentActive = this.contragentActive != uidcontragent1c ? uidcontragent1c : '';
    }

    showContragentForm() {
        this.contragentForm = this.formBuilder.group({
            namejur: ['', RxwebValidators.required()],
            inn: ['', RxwebValidators.required()],
            adressjur: [''],
            adressdelivery: ['']
        });
    }

    saveNewContragent()
    {
        if (this.contragentForm.invalid) {
            this.contragentForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.addContragent(this.contragentForm.value).subscribe(resp => {
            this.alert.success('Данные отправлены! Юр.лицо будет добавлено после проверки!');
            this.contragentForm = null;
        });
    }

    deleteContragent() {
        this.auth.deleteContragent(this.confirmContragentDelete).subscribe(resp => {
            this.alert.success('Заявка на удаление юр.лица отправлена.');
            this.confirmContragentDelete = null;
        });
    }

    showEditPasswordForm() {
        this.tab = 3;
        this.editPasswordForm = this.formBuilder.group({
            password_old: ['', [
                RxwebValidators.required()
            ]],
            password: ['', [
                RxwebValidators.required()
            ]],
            password_confirm: ['', [
                RxwebValidators.required(),
                RxwebValidators.compare({fieldName: 'password'})
            ]]
        });
    }

    updatePassword()
    {
        if (this.editPasswordForm.invalid) {
            this.editPasswordForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.updatePassword(this.editPasswordForm.value).subscribe(resp => {
            this.tab = 1;
            this.alert.success('Пароль сохранен!');
            this.editPasswordForm = null;
        });
    }
}
