import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
    mobMainNavHidden = true;
    mobUserNavHidden = true;

    constructor(private router: Router) {
        if (window.screen.width < 1280) {
            this.router.events.subscribe(event => { this.hide(); });
            console.log('mobile');
        }
    }

    toggleMainNav() {
        this.mobMainNavHidden = !this.mobMainNavHidden;
    }

    toggleUserNav() {
        this.mobUserNavHidden = !this.mobUserNavHidden;
    }

    hide() {
        this.mobMainNavHidden = true;
        this.mobUserNavHidden = true;
    }
}
