import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asdate'
})
export class AsdatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value.replace(/^(\d{4})(\d{2})(\d{2}).*$/g, '$3.$2.$1');
  }

}
