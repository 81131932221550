import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-control-error',
  template: `
      <span *ngIf="control.errors && (control.dirty || control.touched)">
          <span *ngIf="control.errors.required">вы не ввели</span>
          <span *ngIf="control.errors.email">емейл не корректный</span>
      </span>
  `
})
export class ErrorComponent {
  @Input() control: FormControl;

  constructor() {

  }

}
