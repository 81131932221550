<div class="header-flex">
    <h1 class="inline-flex text-level-1 text-level-1--no-margin">Расчет / Заказ</h1>

    <div class="inline-flex a-i-center note" *ngIf="calcForm">
        <svg class="svg-icon m-r-16" viewBox="0 0 1 1" width="24" height="24">
            <use xlink:href="/assets/img/sprite.svg#info"></use>
        </svg>
        Для получения цены ножа достаточно заполнить поля
        <span class="rect m-l-8"></span>
    </div>
</div>

<div *ngIf="storedCalculation">
    У вас есть незавершенный расчет, желаете его восстановить?
    <div class="inline-flex a-i-center">
        <button class="btn btn--main btn--md lg-paddings m-l-12" type="button" (click)="restoreCalculation()">
            Да
        </button>
        <button class="btn btn--main btn--md lg-paddings m-l-12" type="button" (click)="forgetStoredCalculation()">
            Нет
        </button>
    </div>
</div>

<form *ngIf="calcForm" [formGroup]="calcForm" (ngSubmit)="showPrice()">
    <div class="calc-box calc-group-1">
        <div class="calc-group-1__row-1">
            <div class="text-level-2 text-level-2--no-margin">Номер инструмента:</div>
            <input class="form-element form-element--sm form-element--sm form-element--sm-padding flex-grow-1" type="text" formControlName="nameorder"/>
            <p class="f-s-12">(маркировка инструмента, останется на пластине)</p>
        </div>
        <div class="calc-group-1__row-2">
            <div class="calc-group-1__col-1">
                <div class="calc-group-1__area-1">
                    <div class="inline-flex a-i-center">
                        <span class="m-r-8 min-width-100">Тип вырубки:</span>
                        <div class="tooltip">
                            <div class="tooltip__mark">?</div>
                            <div class="tooltip__content">Выберите:
                                <ul>
                                    <li><b>«Ротация»</b> – если нож полностью занимает поверхность магнитного вала.</li>
                                    <li><b>«Полуротация»</b> – если нож занимает только часть.</li>
                                    <li><b>«Плоский»</b> – когда высечка производится с плоской поверхности.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="inline-flex flex-wrap m-l-16">
                        <div class="radio full-width js-change-calc-dimension-fields-set">
                            <input class="radio__input" id="type-of-cutting-1" type="radio" [value]="1" formControlName="typefelling" />
                            <label class="radio__label" for="type-of-cutting-1"></label>
                            <label class="radio__caption" for="type-of-cutting-1">Ротация</label>
                        </div>
                        <div class="radio full-width m-t-8 js-change-calc-dimension-fields-set">
                            <input class="radio__input" id="type-of-cutting-2" type="radio" [value]="2" formControlName="typefelling" />
                            <label class="radio__label" for="type-of-cutting-2"></label>
                            <label class="radio__caption" for="type-of-cutting-2">Полуротация</label>
                        </div>
                        <div class="radio full-width m-t-8 js-change-calc-dimension-fields-set">
                            <input class="radio__input" id="type-of-cutting-3" type="radio" [value]="3" formControlName="typefelling" />
                            <label class="radio__label" for="type-of-cutting-3"></label>
                            <label class="radio__caption" for="type-of-cutting-3">Плоский</label>
                        </div>
                    </div>
                </div>
                <div class="calc-group-1__area-2">
                    <div id="js-calc-dimensions-fields-set">


                        <div class="calc-field inline-flex">
                            <div class="calc-field__caption inline-flex a-i-center">
                                <span class="m-r-8 min-width-124 m-t-on-sm-16">
                                    {{ v.typefelling == 3 ? 'Длина ножа' : 'Раппорт м.вала' }}:
                                </span>
                                <div class="tooltip">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content"><b>Раппорт</b> – это длина окружности магнитного вала
                                        в миллиметрах. Обычно этот параметр указывается на самом валу, но только в
                                        количестве зубьев приводной шестеренки. Например, Z80, Z96, Z120 и т.п. Получить
                                        длину окружности вала (раппорт) можно по нехитрой формуле: L=Z*3,175. В
                                        экзотических случаях этот коэффициент может отличаться.
                                    </div>
                                </div>
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">
                                <input class="form-element form-element--sm form-element--filled form-element--sm-padding m-r-8" type="number" formControlName="shaftpitch"/>
                                <span>мм</span>
                                <div class="err-msg">{{ f.shaftpitch.errors?.minNumber?.message }}{{ f.shaftpitch.errors?.maxNumber?.message }}</div>
                            </div>
                        </div>


                        <div class="calc-field inline-flex" *ngIf="v.typefelling == 2">
                            <div class="calc-field__caption inline-flex a-i-center">
                                <span class="m-r-8 min-width-124">Длина ножа:</span>
                                <div class="tooltip">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">
                                        Укажите необходимую длину для полуротационной и плоской высечки.
                                        (В случае ротационной высечки она автоматически равна раппорту печати).
                                    </div>
                                </div>
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">
                                <input class="form-element form-element--sm form-element--filled form-element--sm-padding m-r-8" type="number" formControlName="knifelength">
                                <span>мм</span>
                                <div class="err-msg">{{ f.knifelength.errors?.minNumber?.message }}</div>
                            </div>

                        </div>

                        <div class="calc-field inline-flex">
                            <div class="calc-field__caption inline-flex a-i-center">
                                <span class="m-r-8 min-width-124">Ширина ножа:</span>
                                <div class="tooltip">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">
                                        Укажите размер ножа по ширине для всех типов высечки.
                                        Однозначно не может быть шире вала.
                                    </div>
                                </div>
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">
                                <input class="form-element form-element--sm form-element--filled form-element--sm-padding m-r-8" type="number" formControlName="knifewidth">
                                <span>мм</span>
                                <div class="err-msg">{{ f.knifewidth.errors?.range?.message }}</div>
                            </div>
                        </div>
                        <small *ngIf="v.typecircuit != 3">* ширина ножа - ручной ввод по желанию</small>

                        <div class="calc-field m-t-4 inline-block">
                            <div class="calc-field__caption inline-flex a-i-center min-width-211">
                                <span class="m-r-4 calc-field_caption-with-sub-text"><b>RLL</b>&nbsp;<small>(лазерная закалка)</small></span>
                                <div class="tooltip">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">
                                        Повышение тиражестойкости с помощью лазерной закалки
                                    </div>
                                </div>
                            </div>
                            <div class="calc-field__input inline-block float-right a-i-center">
                                <div class="checkbox checkbox--lg m-b-4">
                                    <input class="checkbox__input" id="laserhardening" type="checkbox" value="1" formControlName="laserhardening" />
                                    <label class="checkbox__label" for="laserhardening">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="calc-field inline-block ">
                            <div class="calc-field__caption inline-flex a-i-center min-width-211">
                                <span class="m-r-4 calc-field_caption-with-sub-text"><b>RAA</b>&nbsp;<small>(антиадгезионное покрытие)</small></span>
                                <div class="tooltip">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">
                                        Предотвращается налипание на нож посторонних субстанций
                                    </div>
                                </div>
                            </div>
                            <div class="calc-field__input inline-block float-right a-i-center">
                                <div class="checkbox checkbox--lg m-b-4">
                                    <input class="checkbox__input" id="antiadhesioncoating" type="checkbox" value="1" formControlName="antiadhesioncoating" />
                                    <label class="checkbox__label" for="antiadhesioncoating">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>



                    </div>



                </div>
            </div>
            <div class="calc-group-1__col-2">
                <div class="calc-group-1__area-3">
                    <div class="calc-field inline-flex">
                        <div class="calc-field__caption inline-flex a-i-center">
                            <span class="m-r-8">
                                Толщина подложки:
                            </span>
                            <div class="tooltip tooltip__down">
                                <div class="tooltip__mark">?</div>
                                <div class="tooltip__content">Самый важный параметр для определения высоты ножа! Обычно
                                    указывается в микронах и стандартно равна 55 мкм. Однако все точные данные есть в
                                    паспорте материала! Сомневаетесь – напишите название материала в комментариях или
                                    приложите спецификацию. Если высечка сквозная – поставьте значение «0».
                                </div>
                            </div>
                        </div>
                        <div class="calc-field__input inline-flex a-i-center">
                            <input class="form-element form-element--sm form-element--filled form-element--sm-padding m-r-8" type="number" formControlName="substratethickness"/>
                            <div class="err-msg">{{ f.substratethickness.errors?.range?.message }}</div>
                        </div>
                    </div>

                    <div class="calc-field inline-flex">
                        <div class="calc-field__caption inline-flex a-i-center">
                            <span class="m-r-8">
                                Высота ножа:
                            </span>
                            <div class="tooltip">
                                <div class="tooltip__mark">?</div>
                                <div class="tooltip__content">
                                    Введите высоту ножа. Если сомневаетесь - напишите в комментариях спецификацию на материал.
                                    Мы подберем высоту исходя из толщины подложки.
                                </div>
                            </div>
                        </div>
                        <div class="calc-field__input inline-flex a-i-center">
                            <input class="form-element form-element--sm form-element--filled form-element--sm-padding m-r-8" type="number" formControlName="knifeheight" />
                            <span>мкм</span>
                            <div class="err-msg">{{ f.knifeheight.errors?.range?.message }}</div>
                        </div>
                    </div>
                </div>
                <div class="calc-group-1__area-4">
                    <div class="calc-group-1__subarea-1">
                        <div class="inline-flex a-i-center m-b-8">Вырубаемый материал:
                            <div class="tooltip m-l-4">
                                <div class="tooltip__mark">?</div>
                                <div class="tooltip__content">Разный материал – разный угол заточки ножа. Выберите из
                                    списка или добавьте свой вариант
                                </div>
                            </div>
                        </div>
                        <div class="m-l-on-sm-16">
                            <div class="radio full-width js-change-calc-grinding-angle">
                                <input class="radio__input" id="type-of-material-1" type="radio" value="Бумага" name="type-of-material" (click)="changeAngle(90)" [checked]="v.anglesharpening == 90" />
                                <label class="radio__label" for="type-of-material-1"></label>
                                <label class="radio__caption" for="type-of-material-1">Бумага</label>
                            </div>
                            <div class="radio full-width js-change-calc-grinding-angle">
                                <input class="radio__input" id="type-of-material-2" type="radio" value="Термобумага" name="type-of-material" (click)="changeAngle(80)" [checked]="v.anglesharpening == 80" />
                                <label class="radio__label" for="type-of-material-2"></label>
                                <label class="radio__caption" for="type-of-material-2">Термобумага </label>
                            </div>
                            <div class="radio full-width js-change-calc-grinding-angle">
                                <input class="radio__input" id="type-of-material-3" type="radio" value="ПП, ПЕ, ПВХ" name="type-of-material" (click)="changeAngle(70)" [checked]="v.anglesharpening == 70" />
                                <label class="radio__label" for="type-of-material-3"></label>
                                <label class="radio__caption" for="type-of-material-3">ПП, ПЕ, ПВХ</label>
                            </div>
                            <div class="radio full-width js-change-calc-grinding-angle">
                                <input class="radio__input" id="type-of-material-4" type="radio" value="Специальный" name="type-of-material"(click)="changeAngle(null)" [checked]="[90,80,70].indexOf(v.anglesharpening) == -1" />
                                <label class="radio__label" for="type-of-material-4"></label>
                                <label class="radio__caption" for="type-of-material-4">Специальный</label>
                            </div>
                        </div>
                    </div>
                    <div class="calc-group-1__subarea-2">
                        <div class="inline-flex a-i-center m-b-8">Угол заточки кромки
                            <div class="tooltip m-l-4 m-r-4">
                                <div class="tooltip__mark">?</div>
                                <div class="tooltip__content">Определяется, исходя из высекаемого материала. Как
                                    правило, бумага - 90, термобумага – 80, ПП, ПЕ, ПВХ – 70 градусов. Можете установить
                                    свой вариант.
                                </div>
                            </div>
                        </div>
                        <div class="calc-grinding-angle">
                            <input type="number" class="calc-grinding-angle__input" id="js-calc-grinding-angle"  formControlName="anglesharpening">
                        </div>
                        <div class="err-msg">{{ f.anglesharpening.errors?.range?.message }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="calc-box">
        <div class="calc-group-2">
            <div class="calc-group-2__col-1">
                <div class="calc-group-2__area-1">
                    <div class="calc-group-2__subarea-1">
                        <span class="m-r-8 min-width-128">Форма этикетки:</span>
                        <div class="tooltip">
                            <div class="tooltip__mark">?</div>
                            <div class="tooltip__content">На ноже одинаковые окружности или прямоугольники – выберите
                                «Круг» или «Прямоугольник» соответственно. В случае высечки сложной формы, а также
                                наличии разных по форме элементов, перфорации, биговки и т.п. – нажмите «Свободный
                                контур».
                            </div>
                        </div>
                    </div>
                    <div class="calc-group-2__subarea-2">
                        <div class="radio full-width js-change-calc-stickers-fields-set">
                            <input class="radio__input" id="sticker-form-1" type="radio" [value]="2" formControlName="typecircuit" />
                            <label class="radio__label" for="sticker-form-1"></label>
                            <label class="radio__caption" for="sticker-form-1">Прямоугольник</label>
                        </div>
                        <div class="radio full-width js-change-calc-stickers-fields-set">
                            <input class="radio__input" id="sticker-form-2" type="radio" [value]="1" formControlName="typecircuit"/>
                            <label class="radio__label" for="sticker-form-2"></label>
                            <label class="radio__caption" for="sticker-form-2">Круг</label>
                        </div>
                        <div class="radio full-width js-change-calc-stickers-fields-set">
                            <input class="radio__input" id="sticker-form-3" type="radio" [value]="3" formControlName="typecircuit"/>
                            <label class="radio__label" for="sticker-form-3"></label>
                            <label class="radio__caption" for="sticker-form-3">Свободный контур</label>
                        </div>
                    </div>
                </div>
                <div class="calc-group-2__area-2">
                    <img class="m-b-12" src="/assets/img/calc-scheme.png" alt="Схема" *ngIf="v.typecircuit < 3">

                    <div class="calc-group-2__subarea-3" *ngIf="v.typecircuit < 3">
                        <div class="calc-group-2__item-1">
                            <div id="js-calc-stickers-fields-set">

                                <div class="calc-field flex flex-wrap" *ngIf="v.typecircuit == 2 || v.typecircuit == 3" title="Ширина (мм)">
                                    <div class="calc-field__caption inline-flex a-i-center">
                                        <span>L</span>
                                        <span class="m-l-16">=</span>
                                    </div>
                                    <div class="calc-field__input inline-flex a-i-center">
                                        <input class="form-element form-element--sm form-element--sm-padding form-element--required" type="number" formControlName="width" />
                                        <br><div class="err-msg">{{ f.width.errors?.range?.message }}</div>
                                    </div>
                                </div>

                                <div class="calc-field flex flex-wrap m-t-16" *ngIf="v.typecircuit == 2 || v.typecircuit == 3" title="Длина (мм)">
                                    <div class="calc-field__caption inline-flex a-i-center">
                                        <span>B</span>
                                        <span class="m-l-16">=</span>
                                    </div>
                                    <div class="calc-field__input inline-flex a-i-center">
                                        <input class="form-element form-element--sm form-element--sm-padding form-element--required" type="number" formControlName="length"/>
                                        <div class="err-msg">{{ f.length.errors?.range?.message }}</div>
                                    </div>
                                </div>

                                <div class="calc-field flex flex-wrap m-t-16" *ngIf="v.typecircuit == 2" title="Радиус скругления">
                                    <div class="calc-field__caption inline-flex a-i-center">
                                        <span>R</span>
                                        <span class="m-l-16">=</span>
                                    </div>
                                    <div class="calc-field__input inline-flex a-i-center">
                                        <input class="form-element form-element--sm form-element--sm-padding form-element--required" type="number" formControlName="radiusfillet" />
                                        <div class="err-msg">{{ f.radiusfillet.errors?.maxNumber?.message }}</div>
                                        &nbsp;
                                        <div class="tooltip hidden-on-sm">
                                            <div class="tooltip__mark">?</div>
                                            <div class="tooltip__content">Если R<0.6 мм, то стоимость увеличивается</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="calc-field flex m-t-16" *ngIf="v.typecircuit == 1">
                                    <div class="calc-field__caption inline-flex a-i-center"><span class="min-width-20">D</span><span class="m-l-16">=</span></div>
                                    <div class="calc-field__input inline-flex a-i-center">
                                        <input class="form-element form-element--sm form-element--sm-padding form-element--required" type="number" formControlName="diameter">
                                        <div class="err-msg">{{ f.diameter.errors?.range?.message }}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="calc-field flex m-t-16" title="расстояние между ручьями: 0 – если, количество ручьев равно 1">
                                <div class="calc-field__caption inline-flex a-i-center">
                                    <span>A1</span>
                                    <span class="m-l-16">=</span>
                                </div>
                                <div class="calc-field__input inline-flex a-i-center">
                                    <input class="form-element form-element--sm form-element--filled form-element--sm-padding" type="number" formControlName="distancestreams" [readOnly]="v.amountstreams == 1" />
                                    <div class="err-msg">{{ f.distancestreams.errors?.minNumber?.message }}</div>
                                </div>
                            </div>
                            <div class="calc-field flex m-t-16"
                                 title="расстояние между повторениями: заполняется вручную, если количество повторений на раппорте больше 1 и тип вырубки – полуротация. В остальных случаях, рассчитывается автоматически.">
                                <div class="calc-field__caption inline-flex a-i-center">
                                    <span>A2</span>
                                    <span class="m-l-16">=</span>
                                </div>
                                <div class="calc-field__input inline-flex a-i-center">
                                    <input class="form-element form-element--sm form-element--filled form-element--sm-padding" type="number" formControlName="distancerepetitionsrapport" [readOnly]="v.typefelling == 1 || v.amountrepetitionsrapport == 1" />
                                    <div class="err-msg">{{ f.distancerepetitionsrapport.errors?.minNumber?.message }}</div>
                                </div>
                            </div>




                        </div>
                        <div class="calc-group-2__item-2">

                            <!-- amountstreams -->
                            <div class="inline-flex a-i-center m-b-8">Количество ручьев
                                <div class="tooltip m-l-4 m-r-4">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">Ручей - последовательно идущие друг за другом (по
                                        раппорту) экземпляры готовой продукции. Обычно ширина запечатываемого материала
                                        позволяет разместить несколько рядов этикеток, билетов или бирок - каждый
                                        отдельный ряд и называется ручьем.
                                    </div>
                                </div>
                                :
                            </div>
                            <input type="number" class="form-element form-element--bordered form-element--required max-width-120" formControlName="amountstreams">

                            <!-- amountrepetitionsrapport -->
                            <div class="inline-flex a-i-center m-t-16 m-b-8">Этикеток в ручье
                                <div class="tooltip m-l-4 m-r-4">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">
                                        Количество этикеток в ручье (повторов на раппорте).
                                    </div>
                                </div>
                                :
                            </div>
                            <input type="number" class="form-element form-element--bordered form-element--required max-width-120" formControlName="amountrepetitionsrapport">


                        </div>
                    </div>




                    <div class="calc-group-2__elements" *ngIf="v.typecircuit == 3">

                        <div class="calc-field flex">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Для отправки заказа
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">
                                <input class="custom-file-input" type="file" (change)="onFileChange($event)" />
                            </div>
                        </div>
                        <div class="file-input__filename">{{ v.filename }}</div>


                        <div class="calc-field calc-group-2__elements--field-checkbox flex m-t-8">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Несколько углов заточки на одном штампе
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">

                                <div class="checkbox checkbox--lg m-l-12 m-t-4 m-b-4">
                                    <input class="checkbox__input" id="multipleanglessharpening" type="checkbox" value="1" formControlName="multipleanglessharpening" />
                                    <label class="checkbox__label" for="multipleanglessharpening">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div class="calc-field calc-group-2__elements--field-checkbox flex">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Несколько высот кромки на одном штампе
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">

                                <div class="checkbox checkbox--lg m-l-12 m-t-4 m-b-4">
                                    <input class="checkbox__input" id="multipleedgeheights" type="checkbox" value="1" formControlName="multipleedgeheights" />
                                    <label class="checkbox__label" for="multipleedgeheights">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div class="calc-field calc-group-2__elements--field-checkbox flex m-t-8">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Бесконечный нож
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">

                                <div class="checkbox checkbox--lg m-l-12 m-t-4 m-b-4">
                                    <input class="checkbox__input" id="unlimknife" type="checkbox" value="1" formControlName="unlimknife" />
                                    <label class="checkbox__label" for="unlimknife">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div class="calc-field calc-group-2__elements--field-checkbox flex m-t-8">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Перфорация
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">

                                <div class="checkbox checkbox--lg m-l-12 m-t-4 m-b-4">
                                    <input class="checkbox__input" id="perforation" type="checkbox" value="1" formControlName="perforation" />
                                    <label class="checkbox__label" for="perforation">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>

                            </div>
                        </div>


                        <div class="calc-field calc-group-2__elements--field-checkbox flex m-t-8" *ngIf="v.perforation">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Высота перфорации (микрон)

                                <div class="tooltip m-l-4 m-r-4">
                                    <div class="tooltip__mark">?</div>
                                    <div class="tooltip__content">
                                        от 100 до 1000 микрон
                                    </div>
                                </div>
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">
                                <input class="form-element form-element--sm form-element--required form-element--sm-padding" type="number" formControlName="perforationheight" />
                                <div class="err-msg">{{ f.perforationheight.errors?.range?.message }}</div>
                            </div>
                        </div>


                        <div class="calc-field calc-group-2__elements--field-checkbox flex m-t-8">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Микроперфорация
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">

                                <div class="checkbox checkbox--lg m-l-12 m-t-4 m-b-4">
                                    <input class="checkbox__input" id="microperforation" type="checkbox" value="1" formControlName="microperforation" />
                                    <label class="checkbox__label" for="microperforation">
                                        <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                                            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                                        </svg>
                                    </label>
                                </div>

                            </div>
                        </div>


                        <div class="calc-field calc-group-2__elements--field-checkbox flex m-t-8" *ngIf="v.microperforation">
                            <div class="calc-field__caption inline-flex a-i-center">
                                Количество резов
                            </div>
                            <div class="calc-field__input inline-flex a-i-center">
                                <input class="form-element form-element--sm form-element--required form-element--sm-padding" type="number" formControlName="qtycut" />
                            </div>
                        </div>



                        <div class="m-t-16">Для оценки стоимости добавьте элементы<br> <u>согласно правилам:</u></div>

                        <div class="calc--order_element" [formGroup]="element" *ngFor="let element of felements.controls; let i = index">
                            <div class="calc--order_element--number">
                                <span>Элемент {{ i+1 }}</span>
                                <a class="calc--order_element--remove__btn text-link" (click)="removeElement(i)">
                                    <svg class="svg-icon" viewBox="0 0 1 1" width="20" height="20">
                                        <use xlink:href="/assets/img/sprite.svg#cross-in-circle"></use>
                                    </svg>
                                </a>
                            </div>

                            <div class="calc-field flex m-t-16">
                                <div class="calc-field__caption inline-flex a-i-center">
                                    <span>Периметр одного элемента в мм</span>
                                    <span class="m-l-16">=</span>
                                </div>
                                <div class="calc-field__input inline-flex a-i-center">
                                    <input class="form-element form-element--sm form-element--required form-element--sm-padding" type="number" formControlName="perimeter" />
                                </div>
                            </div>

                            <div class="calc-field flex m-t-16">
                                <div class="calc-field__caption inline-flex a-i-center">
                                    <span>Количество повторений на ноже</span>
                                    <span class="m-l-16">=</span>
                                </div>
                                <div class="calc-field__input inline-flex a-i-center">
                                    <input class="form-element form-element--sm form-element--required form-element--sm-padding" type="number" formControlName="amountrepetitionsrapport" />
                                </div>
                            </div>
                        </div>

                        <button *ngIf="v.elements.length < 10" type="button" class="btn btn--main" (click)="addElement()">Добавить элемент</button>

                    </div>






                </div>
            </div>
            <div id="knife_preview_wrap" class="calc-group-2__col-2" #knife_preview_wrap>
                <div id="knife_preview_img" *ngIf="knifePreviewParams else knifePreviewNo">
                    <div class="row" *ngFor="let row of knifePreviewParams" [ngStyle]="row.style">
                        <div class="itm" *ngFor="let itemStyle of row.items" [ngStyle]="itemStyle"></div>
                    </div>
                </div>
                <ng-template #knifePreviewNo>
                    <div id="knife_preview_msg" class="t-a-center">{{ knifePreviewError }}</div>
                </ng-template>
                <div id="knife_preview_direction">Направление &nbsp; вырубки &nbsp; ⟶</div>
            </div>
        </div>

        <div class="calc-field flex m-t-8" *ngIf="v.typecircuit <= 2">
            <div class="err-msg-static a-i-center" *ngIf="v.distancestreams >= 1.2 && v.distancestreams < 1.5">
                A1: значение {{ v.distancestreams }} для производства возможно<br> Но рекомендуемый минимум 1.5
            </div>
            <div class="err-msg-static a-i-center" *ngIf="v.distancerepetitionsrapport >= 1.2 && v.distancerepetitionsrapport < 1.5">
                А2: значение {{ v.distancerepetitionsrapport }} для производства возможно<br> Но рекомендуемый минимум 1.5
            </div>
        </div>

        <div class="calc-field flex m-t-16" *ngIf="v.typecircuit <= 2">
            <div class="calc-field__caption inline-flex a-i-center">
                <span>W <small>по кромкам</small></span>
                <div class="tooltip m-l-4 m-r-4">
                    <div class="tooltip__mark">?</div>
                    <div class="tooltip__content">Расстояние между крайними режущими кромками элементов</div>
                </div>
                :
            </div>
            <div class="calc-field__input inline-flex a-i-center" *ngIf="v.amountstreams">
                <span *ngIf="v.typecircuit == 1 && v.diameter">
                    {{ roundAndFix((v.diameter + v.distancestreams) * v.amountstreams - v.distancestreams, 3) }} мм
                </span>
                <span *ngIf="v.typecircuit == 2 && v.width">
                    {{ roundAndFix((v.width + v.distancestreams) * v.amountstreams - v.distancestreams, 3) }} мм
                </span>
            </div>
        </div>

        <div class="calc-field flex m-t-16" *ngIf="v.typefelling == 1 && v.typecircuit <= 2">
            <div class="calc-field__caption inline-flex a-i-center">
                <span>S <small>материала</small></span>
                <div class="tooltip m-l-4 m-r-4">
                    <div class="tooltip__mark">?</div>
                    <div class="tooltip__content">Площадь материала (м<sup>2</sup>) на 1 оборот ножа</div>
                </div>
                :
            </div>
            <div class="calc-field__input inline-flex a-i-center" *ngIf="v.shaftpitch">
                <span *ngIf="v.typecircuit == 1 && v.diameter">
                    {{ roundAndFix((v.diameter + v.distancestreams) * v.amountstreams * v.shaftpitch * 0.000001, 5) }} м<sup>2</sup>
                </span>
                <span *ngIf="v.typecircuit == 2 && v.width">
                    {{ roundAndFix((v.width + v.distancestreams) * v.amountstreams * v.shaftpitch * 0.000001, 5) }} м<sup>2</sup>
                </span>
            </div>
        </div>


        <p class="text-level-3 m-b-8 m-t-16">Комментарий:</p>
        <textarea class="form-element form-element--sm full-width" id="order-wishes" name="wishes" rows="1" placeholder="Опишите здесь все свои пожелания и уточнения по заказу" formControlName="comments"></textarea>
    </div>
    <div class="calc-controls">
        <div class="inline-flex flex-wrap j-c-center a-i-center">
            <div class="inline-flex a-i-center">
                <button class="btn btn--main btn--md lg-paddings" id="js-calc-order-price" type="button" (click)="showPrice()" [disabled]="calcForm.pristine">
                    Показать цену
                </button>
                <div class="calc-price" >
                    {{ calculationResult && calcForm.pristine ? calculationResult.sumorder : 0 }} ₽
                </div>
            </div>
            <div class="checkbox checkbox--lg m-l-12 m-t-4 m-b-4">
                <label class="checkbox__label" [class.active]="v.rushorder" (click)="setRushOrderAndShowPrice()">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                        <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                    </svg>
                </label>
                <label class="checkbox__caption" (click)="setRushOrderAndShowPrice()">Срочно</label>
            </div>
            <div class="calc-date m-l-12" *ngIf="v.shippingdate && calcForm.pristine">
                Дата отгрузки:&#32;<br><b>{{ v.shippingdate | asdate }}</b>
            </div>
            <div class="m-l-12" *ngIf="calculationResult && calcForm.pristine">
                Расчет сохранен (№&#32;<b> {{ calculationResult.orderid }}</b>)
            </div>
        </div>
        <div class="inline-flex a-i-center">
            <button class="btn btn--main btn--md lg-paddings m-l-12" type="button" (click)="showCheckoutForm()">Заказать</button>
        </div>
    </div>
</form>






<div>
    <div class="modal-overlay is-active" *ngIf="unableCalculate">
        <div class="modal modal--sm t-a-center">
            <div class="modal__wrapper">
                <h3 class="modal__header">Сервер недоступен</h3>
                <div class="modal__content">
                    <p>
                        Попытайтесь позже отправить запрос повторно.
                    </p>
                    <br>
                    <p>
                        Связь скоро будет восстановлена,<br> извините за неудобства.
                    </p>
                    <a class="btn btn--main btn--md f-w-700 m-t-24" (click)="rememberCalculation()">Запомнить расчет и выйти</a>
                </div>
            </div>
            <button class="modal__close" type="button" (click)="this.unableCalculate = null">
                <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                    <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
                </svg>
            </button>
        </div>
    </div>

    <app-order-form
        *ngIf="checkoutOrder"
        [order]="checkoutOrder"
        (close)="checkoutOrder = null"
        (closeSuccessModal)="checkoutOrder = null; calculationResult = null;"
    ></app-order-form>
</div>
