import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/auth/login/login.component';
import {RegisterComponent} from './components/auth/register/register.component';
import {UserAreaComponent} from './components/cabinet/user-area/user-area.component';
import {OrderListComponent} from './components/cabinet/order-list/order-list.component';
import {CalculatorComponent} from './components/cabinet/calculator/calculator.component';
import {AuthGuard} from './guards/auth.guard';
import {ActivateComponent} from './components/auth/activate/activate.component';
import {ResetPassComponent} from './components/auth/reset-pass/reset-pass.component';
import {PageNotFoundComponent} from './components/pages/page-not-found/page-not-found.component';
import {NoAuthGuard} from './guards/no-auth.guard';
import {HomePageComponent} from './components/pages/home-page/home-page.component';
import {TextPageComponent} from './components/pages/text-page/text-page.component';
import {ContactsPageComponent} from './components/pages/contacts-page/contacts-page.component';
import {OrderFormComponent} from './components/shared/order-form/order-form.component';


const routes: Routes = [
    { path: '', component: HomePageComponent},
    { path: 'login', component: LoginComponent, canActivate: [NoAuthGuard]},
    { path: 'register', component: RegisterComponent, canActivate: [NoAuthGuard]},
    { path: 'activate/:user_id/:code', component: ActivateComponent, canActivate: [NoAuthGuard]},
    { path: 'reset-pass/:user_id/:code', component: ResetPassComponent, canActivate: [NoAuthGuard]},
    { path: 'reset-pass', component: ResetPassComponent, canActivate: [NoAuthGuard]},
    { path: 'calculator', component: CalculatorComponent, canActivate: [AuthGuard]},
    { path: 'calculator/:uidorder1c', component: CalculatorComponent, canActivate: [AuthGuard]},
    { path: 'orders', component: OrderListComponent, canActivate: [AuthGuard]},
    { path: 'user-area', component: UserAreaComponent, canActivate: [AuthGuard]},
    { path: 'page/products', component: TextPageComponent },
    { path: 'page/benefits', component: TextPageComponent },
    { path: 'page/quality-service', component: TextPageComponent },
    { path: 'page/useful', component: TextPageComponent },
    { path: 'contacts', component: ContactsPageComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
