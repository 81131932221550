<app-header></app-header>

<div class="wrapper">
    <main class="main">
        <ngx-alerts></ngx-alerts>

        <router-outlet></router-outlet>

        <ngx-spinner bdColor="#81b0e86b" size="medium" color="#fff" type="line-scale-pulse-out" [fullScreen]="true">
        </ngx-spinner>
    </main>

    <app-sidebar></app-sidebar>
</div>


