import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {AlertService} from 'ngx-alerts';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService,
        private alert: AlertService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.auth.userValue;

        if (user) {
            return true;
        }

        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

        if (state.url.includes('calculator')) {
            this.alert.info('Для использования калькулятора необходимо Войти или Зарегистрироваться.');
        } else if (state.url.indexOf('orders') >= 0) {
            this.alert.info('Для просмотра истории заказов необходимо Войти или Зарегистрироваться.');
        }

        this.router.navigate(['/login']);

        return false;
    }

}
