import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInputPassword]'
})
export class InputPasswordDirective {
    private shown = false;
    private templateShow = '<img src="/assets/img/eye-on.svg" alt="eye-on">';
    private templateHide = '<img src="/assets/img/eye-off.svg" alt="eye-off">';

    constructor(private el: ElementRef) {
        this.setup();
    }

    toggle(span: HTMLElement) {
        this.shown = !this.shown;

        if (this.shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            span.innerHTML = this.templateHide;
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            span.innerHTML = this.templateShow;
        }
    }

    setup() {
        const parent = this.el.nativeElement.parentNode;
        const span = document.createElement('span');

        span.className = 'password-eye-icon';
        span.innerHTML = this.templateShow;

        span.addEventListener('click', (event) => {
            this.toggle(span);
        });

        parent.prepend(span);
    }
}
