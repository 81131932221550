import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { ConfigService } from '../../../services/config.service';
import { Contragent } from '../../../models/contragent';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    activeLanguage: 'RU' | 'ENG' = 'RU';
    primaryContragent: Contragent;
    user: any;

    constructor(
        public auth: AuthService,
        public mobileNavbar: NavbarService,
        public config: ConfigService
    ) { }

    ngOnInit(): void {
        console.log('ngOnInit called');
        if (this.auth.isLoggedIn()) {
            console.log('User is logged in');
            this.auth.getProfileData().subscribe(user => {
                this.auth.setUser(user);
                this.user = user;
                this.primaryContragent = this.getPrimaryContragent();
            });
        } else {
            console.log('User is not logged in');
        }
    }

    setLanguage(language: 'RU' | 'ENG'): void {
        this.activeLanguage = language;
    }

    getPrimaryContragent(): Contragent {
        const contragentsArray = this.user?.contragents || [];
        return contragentsArray.length > 0 ? contragentsArray[0] : null;
    }

    getTotalDebt(): number {
        if (!this.user?.contragents) return 0;
        return this.user.contragents.reduce((sum, contragent) => sum + (contragent.totaldebt || 0), 0);
    }

    getTotalArrears(): number {
        if (!this.user?.contragents) return 0;
        return this.user.contragents.reduce((sum, contragent) => sum + (contragent.arrears || 0), 0);
    }
}
