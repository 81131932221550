import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReactiveFormConfig, RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {FormGroup} from '@angular/forms';
import {AlertService} from 'ngx-alerts';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit {
    @HostBinding('class') classes = 'content content--xsm a-s-center';
    step = 'forgot';
    forgotPasswordForm: FormGroup;
    resetPasswordForm: FormGroup;

    resetPasswordLinkSend: boolean;
    passwordWasChanged: boolean;

    constructor(
        private route: ActivatedRoute,
        private alert: AlertService,
        private formBuilder: RxFormBuilder,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        if (this.route.snapshot.params.code) {
            this.step = 'reset';
            this.showResetPasswordForm();
        } else {
            this.showForgotPasswordForm();
        }
    }

    showForgotPasswordForm() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [
                RxwebValidators.required(),
                RxwebValidators.email()
            ]]
        });
    }

    showResetPasswordForm() {
        this.resetPasswordForm = this.formBuilder.group({
            user_id: [''],
            code: [''],
            password: ['', [
                RxwebValidators.required()
            ]],
            password_confirm: ['', [
                RxwebValidators.required(),
                RxwebValidators.compare({fieldName: 'password'})
            ]]
        });

        this.resetPasswordForm.patchValue(this.route.snapshot.params);
    }

    submitForgotPasswordForm() {
        if (this.forgotPasswordForm.invalid) {
            this.forgotPasswordForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.forgotPassword(this.forgotPasswordForm.value.email).subscribe(resp => {
            this.forgotPasswordForm = null;
            this.resetPasswordLinkSend = true;
        });
    }

    submitResetPasswordForm() {
        if (this.resetPasswordForm.invalid) {
            this.resetPasswordForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.resetPassword(this.resetPasswordForm.value).subscribe(resp => {
            this.resetPasswordForm = null;
            this.passwordWasChanged = true;
        });
    }
}
