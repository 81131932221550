import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, Directive, ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiInterceptor} from './api.interceptor';
import {AlertModule} from 'ngx-alerts';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ErrorComponent } from './components/shared/error/error.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { RegisterComponent } from './components/auth/register/register.component';
import {
    ErrorMessageBindingStrategy,
    ReactiveFormConfig,
    RxReactiveFormsModule,
    ValidationAlphabetLocale
} from '@rxweb/reactive-form-validators';
import { UserAreaComponent } from './components/cabinet/user-area/user-area.component';
import { OrderListComponent } from './components/cabinet/order-list/order-list.component';
import { CalculatorComponent } from './components/cabinet/calculator/calculator.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AsdatePipe } from './pipes/asdate.pipe';
import { ActivateComponent } from './components/auth/activate/activate.component';
import { ResetPassComponent } from './components/auth/reset-pass/reset-pass.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { TextPageComponent } from './components/pages/text-page/text-page.component';
import { ContactsPageComponent } from './components/pages/contacts-page/contacts-page.component';
import { OrderFormComponent } from './components/shared/order-form/order-form.component';
import { EnvPipe } from './pipes/env.pipe';
import {ErrorService} from './services/error.service';
import { WhoopsComponent } from './components/shared/whoops/whoops.component';
import { PrivacyTextComponent } from './components/shared/privacy-text/privacy-text.component';
import { InputPasswordDirective } from './directives/input-password.directive';
import { InputPhoneDirective } from './directives/input-phone.directive';
import {ConfigService} from './services/config.service';

ReactiveFormConfig.set({
    globalErrorMsg: 'В форме есть ошибки, или не все обязательные поля заполнены!',
    validationMessage: {
        required: 'Пожалуйста, заполните это поле',
        email: 'Некорректный e-mail',
        minLength: 'не менее {{1}} символов',
        maxLength: 'не более {{1}} символов',
        minNumber: 'мин {{1}}',
        maxNumber: 'макс {{1}}',
        mask: 'введите телефон',
        compare: 'пароли не совпадают',
        range: 'от {{1}} до {{2}}',
        lessThan: 'меньше чем {{1}}',
        password: {
            minLength: 'пароль слишком короткий',
            maxLength: 'пароль слишком длинный',
            digit: 'добавьте числа и символы №!#@*&',
            specialCharacter: 'добавьте числа и символы №!#@*&'
        },
        phoneNum: 'Некорректный телефон'
    },
    defaultValidationLocale: {
        alpha: ValidationAlphabetLocale.russian,
        alphaNumeric: ValidationAlphabetLocale.russian
    },
    reactiveForm: {
        errorMessageBindingStrategy: ErrorMessageBindingStrategy.OnTouchedOrSubmit
    }
});

@NgModule({
    declarations: [
        EnvPipe,
        AsdatePipe,
        AppComponent,
        LoginComponent,
        ErrorComponent,
        HeaderComponent,
        SidebarComponent,
        RegisterComponent,
        UserAreaComponent,
        OrderListComponent,
        CalculatorComponent,
        ActivateComponent,
        ResetPassComponent,
        PageNotFoundComponent,
        HomePageComponent,
        TextPageComponent,
        ContactsPageComponent,
        OrderFormComponent,
        WhoopsComponent,
        PrivacyTextComponent,
        InputPasswordDirective,
        InputPhoneDirective
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        AlertModule.forRoot({maxMessages: 5, timeout: 5000, position: 'left'}),
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        NgxPaginationModule,
        FormsModule
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorService },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: (config: ConfigService) =>  () => config.load()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
