import {prop, required} from '@rxweb/reactive-form-validators';

export class Element {
    @prop()
    elementnumber: number;

    @required()
    perimeter: number;

    @prop()
    lengthelement = 0;

    @prop()
    widthelement = 0;

    @required()
    amountrepetitionsrapport: number;

    @prop()
    amountstreams = 1;

    @prop()
    amountangles06 = 0; // (количество углов со скруглением радиуса меньше 0.6 мм, может быть равен 0)
}
