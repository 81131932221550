<h1 class="text-level-1 t-a-center m-b-on-lg-48">Восстановление пароля</h1>

<div *ngIf="forgotPasswordForm">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPasswordForm()">
        <div class="flex flex-wrap m-t-on-lg-48">
            <div class="form-group">
                <input class="form-element" id="login-password" type="text" formControlName="email" />
                <label class="form-label" for="login-password">
                    Введите e-mail<span class="danger-color">*</span>
                    <span class="err-msg">{{ forgotPasswordForm.controls.email['errorMessage'] }}</span>
                </label>
            </div>
        </div>
        <div class="flex j-c-center">
            <button class="btn btn--main btn--lg m-t-on-lg-24" type="submit">Восстановить</button>
        </div>
    </form>
</div>

<div *ngIf="resetPasswordForm">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
        <input type="hidden" formControlName="user_id">
        <input type="hidden" formControlName="code">
        <div class="flex flex-wrap m-t-on-lg-48">
            <div class="form-group">
                <input class="form-element" id="new-password-password" type="password" formControlName="password" />
                <label class="form-label" for="new-password-password">
                    Новый пароль<span class="danger-color">*</span>
                    <span class="err-msg">{{ resetPasswordForm.controls.password['errorMessage'] }}</span>
                </label>
            </div>
            <div class="form-group">
                <input class="form-element" id="new-password-repeat-password" type="password" formControlName="password_confirm" />
                <label class="form-label" for="new-password-repeat-password">
                    Повторите пароль<span class="danger-color">*</span>
                    <span class="err-msg">{{ resetPasswordForm.controls.password_confirm['errorMessage'] }}</span>
                </label>
            </div>
        </div>
        <div class="flex j-c-center">
            <button class="btn btn--main btn--lg m-t-on-lg-24" type="submit">Восстановить</button>
        </div>
    </form>
</div>

<div class="t-a-center" *ngIf="resetPasswordLinkSend">
    <div class="cheque-mark m-b-on-lg-48">
        <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23">
            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
        </svg>
    </div>
    <p class="m-t--24">Ссылка на изменение пароля была успешно отправлена Вам на почту. Следуйте инструкция в письме.</p>
</div>

<div class="t-a-center" *ngIf="passwordWasChanged">
    <div class="cheque-mark m-b-on-lg-48">
        <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23">
            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
        </svg>
    </div>
    <p class="m-t--24">Пароль был успешно обновлен. Теперь можете войти в ЛК.</p>
</div>
