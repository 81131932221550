import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {Order} from '../../../models/order';
import {PaginationControlsDirective} from 'ngx-pagination';
import {Router} from '@angular/router';
import {AlertService} from 'ngx-alerts';
import {ReactiveFormConfig, RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {FormGroup} from '@angular/forms';
import {User} from '../../../models/user';
import {AuthService} from '../../../services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
    @HostBinding('class') classes = 'content content--xlg';

    shownOrdersType = 1;

    user: User;
    contragents: object;

    orders: {
        0: Order[],
        1: Order[]
    };

    rows: Order[];

    sort = 'dateorder';

    search = '';

    tab = 1;

    fellingTypes = ['--', 'Ротация', 'Полуротация', 'Плоский'];

    circuitTypes = {
        name: ['-', 'Круг', 'Прямоугольник', 'Свободный'],
        icon: ['-', 'disk', 'rectangle', 'arbitrary']
    };

    statusClass = {
       "завершен": "status--completed",
       "отменен": "status--canceled"
    };

    config = {
        id: 'custom',
        itemsPerPage: 20,
        currentPage: 1,
        totalItems: 0,
        defaultMsg: 'Подождите, идет загрузка списка заказов ...'
    };

    checkoutOrder: Order;

    confirmOrderDelete: Order;

    knifeturnForm: FormGroup;

    mobTableHeaderShow: boolean;

    whoopsMsg: string;

    constructor(
        private orderService: OrderService,
        private formBuilder: RxFormBuilder,
        private alert: AlertService,
        private router: Router,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.loadOrders();
    }

    calcItemsPerPage() {
        let rowH = 73; // 72.82
        let oH = 310 + 80;

        const width = window.innerWidth;

        if (width > 1600) {
            oH = 310 + 16;
        } else if (width > 1280) {
            oH = 260 + 16;
            rowH = 47;
        } else if (width > 960) {
            oH = 200 + 16;
            rowH = 47;
        } else {
            this.config.currentPage = 1;
            this.config.itemsPerPage = 10;
            return;
        }

        this.config.currentPage = 1;
        const count = Math.floor((window.innerHeight - oH - 7  )/rowH);
        this.config.itemsPerPage = count > 1 ? count : 1;

        // мож так  еще var screenHeight = (ionic.Platform.isIOS()) ? window.screen.height : window.innerHeight * window.devicePixelRatio;
    }

    loadOrders() {
        window.onresize = this.calcItemsPerPage.bind(this);
        this.calcItemsPerPage();


        this.orderService.getOrders().subscribe(
            // on success
            orders => {
                if (orders.length == 0) {
                    this.config.defaultMsg = 'Ничего не найдено';
                    return;
                }

                this.orders = orders.reduce(function(rv, o: Order) {
                    rv[o.typeorder].push(o);
                    return rv;
                }, [[], []]);
            },

            // on error
            this.catchError.bind(this),

            // then all complete success
            () => {
                this.auth.getProfileData().subscribe(
                    (user: User) => { this.user = user; },
                    this.catchError.bind(this),
                    this.openOrders.bind(this)
                );
            }
        );
    }

    catchError(err: HttpErrorResponse) {
        if (err.error && err.error.errors) {
            let errorOne = err.error.errors[0];
            this.whoopsMsg = errorOne.details || errorOne.message;
        }
    }

    setRows(rows) {
        this.config.currentPage = 1;
        this.rows = rows.map((o) => {
            o.shown = false;
            return o;
        });
        this.config.totalItems = this.rows.length;
    }

    openOrders() {
        this.tab = 1;
        this.resetSortAndSearchFields();
        this.shownOrdersType = 1;
        this.setRows(this.orders[this.shownOrdersType]);
    }

    openCalculations() {
        this.tab = 2;
        this.shownOrdersType = 0;
        this.resetSortAndSearchFields();
        this.setRows(this.orders[this.shownOrdersType]);
    }

    onPageChange(page: number) {
        this.config.currentPage = page;

        if (window.screen.width < 1280) {
            window.scrollTo(0, 0);
        }
    }

    sortBy(column: string) {
        if (column == 'dateorder') {
            this.setRows(this.rows.concat().sort((a: Order, b: Order) => (a[column] > b[column] ? -1 : 1)));
        } else {
            this.setRows(this.rows.concat().sort((a: Order, b: Order) => a[column] - b[column]));
        }
    }

    searchFor(text) {
        this.search = text;
        this.sort = 'dateorder';
        text = text.toLowerCase();
        this.setRows(this.orders[this.shownOrdersType].filter((order: Order, i: number) => {
            return order.nameorder.toLowerCase().includes(text) || order.orderid.toString().includes(text);
        }));
    }

    resetSortAndSearchFields() {
        this.search = '';
        this.sort = 'dateorder';
    }

    doCheckout(order: Order) {
        this.orderService.canCheckout(order).then(
            () => { this.checkoutOrder = order; },
            (errors: []) => {
                console.log('order to checkout', order);
                this.router.navigate(['/calculator', order.uidorder1c]);
                errors.reverse().forEach((err) => { this.alert.danger(err); });
            }
        );
    }

    cancelOrder(order: Order) {
        this.orderService.cancelOrder(order.uidorder1c).subscribe(
            resp => {
                if (order.typeorder == 1) {
                    this.alert.success('Заказ #'+order.orderid+' отменен!');
                    order.statusorder = 'отменен';
                } else {
                    this.alert.success('Расчет #'+order.orderid+' удален!');
                    this.setRows(this.orders[0] = this.orders[0].filter((o) => o.orderid != order.orderid));
                }

                this.confirmOrderDelete = null;
            },
            () => {
                this.confirmOrderDelete = null;
            }
        );
    }

    repeatOrder(order: Order) {
        this.checkoutOrder = order;
    }

    showKnifeturnForm(order: Order, event) {
        event.stopPropagation();

        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        const currentDate = now.toISOString().slice(0,19);

        this.knifeturnForm = this.formBuilder.group({
            date: [currentDate, RxwebValidators.required()],
            knifemileage: ['', RxwebValidators.required()],
            edition: '',
            printer: '',
            comment: '',
            uidorder1c: [order.uidorder1c]
        });
    }

    setOrderKnifeturn() {
        if (this.knifeturnForm.invalid) {
            this.knifeturnForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.orderService.setKnifeturn(this.knifeturnForm.value).subscribe(resp => {
            this.alert.success('Данные отправлены!');
            this.knifeturnForm = null;
        });
    }

    getOrderKnifeturnHistory(order: Order, event) {
        event.stopPropagation();

        this.orderService.getKnifeturnHistory(order).subscribe(fileUrl => {
            this.alert.success('История загружена!');

            var a = document.createElement("a");
            a.href = fileUrl;
            a.target = "_blank";
            a.click();
        });
    }

    setOrderKnifereCycle(order: Order, event) {
        event.stopPropagation();

        this.orderService.setKnifereCycle(order.uidorder1c).subscribe(resp => {
            this.alert.success('Данные отправлены!');
        });
    }

    downloadFile(data, filename) {
        const blob = new Blob(["\ufeff", data], { type: 'text/csv;charset=utf-8' });
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        // start download
        a.click();
    }
}
