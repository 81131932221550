import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Order} from '../models/order';
import {catchError, map, tap} from 'rxjs/operators';
import {BasicParameters} from '../models/basic-parameters';
import {CacheService} from './cache.service';
import {TypeCircuits} from '../models/enums/type-circuits.enum';
import {TypeFellings} from '../models/enums/type-fellings.enum';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
    orders: Order[];
    basicParametersCache: BasicParameters;

    constructor(
        private http: HttpClient,
        private cache: CacheService
    ) { }

    getOrders(): Observable<Order[]>  {
        if (this.cache.has('orders')) {
            return of(this.cache.get('orders'));
        }

        return this.http.get<Order[]>('/orders').pipe(
            tap(data => {
                data.sort((a: Order, b: Order) => (a['dateorder'] > b['dateorder'] ? -1 : 1));
                this.cache.set('orders', data);
            })
        );
    }

    findOrder(uidorder1c: string): Observable<Order> {
        return this.getOrders().pipe(map(orders => {
            return orders.find((o: Order) => {
                return o.uidorder1c == uidorder1c;
            });
        }));
    }

    addOrder(order: Order): Observable<any> {
        localStorage.removeItem('orders');
        this.cache.clear();

        return this.http.post('/orders', order);
    }

    getBasicParameters(): Observable<BasicParameters> {
        return this.http.get<BasicParameters>('/basicparameters').pipe(
            map(params => this.basicParametersCache = new BasicParameters(params))
        );
    }

    getWidthTechFields(): number {
        if (this.basicParametersCache) {
            localStorage.setItem('widthtechfields', this.basicParametersCache.widthtechfields.toString());

            return this.basicParametersCache.widthtechfields;
        }

        const defvalue = 23;
        const cached = localStorage.getItem('widthtechfields');

        return cached ? parseFloat(cached) : defvalue;
    }

    getShippingDate(rushorder: number = 0, errorCallback = null) {
        return this.getBasicParameters().pipe(
            catchError(errorCallback),
            map((params: BasicParameters) => { return params.getShippingDate(rushorder) })
        ).toPromise();
    }

    cancelOrder(uidorder1c): Observable<any> {
        return this.http.post('/orders/cancel', {uidorder1c: uidorder1c}).pipe(tap(resp => {
            this.cache.clear();
        }));
    }

    getKnifeturnHistory(order): Observable<any> {
        return this.http.post('/orders/knifeturn/history', {
            uidorder1c: order.uidorder1c,
            nameorder: order.nameorder,
            orderid: order.orderid,
            dateorder: order.dateorder
        });
    }

    setKnifeturn(data: object): Observable<any> {
        return this.http.post('/orders/knifeturn', data).pipe(tap(resp => {
            this.cache.clear();
        }));
    }

    setKnifereCycle(uidorder1c): Observable<any> {
        return this.http.post('/orders/kniferecycle', {uidorder1c: uidorder1c}).pipe(tap(resp => {
            this.cache.clear();
        }));
    }

    canCheckout(order: Order) {
        return new Promise((resolve, reject) => {
            let errors = [];

            if (!order.shaftpitch) {
                errors.push('Введите: ' + (order.typefelling == 3 ? 'Длину ножа' : 'Раппорт м.вала'));
            }

            if (order.typefelling == 2 && !order.knifelength) {
                errors.push('Введите: Длину ножа');
            }

            // Прямоуг,Круг. все кроме свободного контура
            if (order.typecircuit < 3) {
                if (order.amountstreams > 1) {
                    if (!order.distancestreams) {
                        errors.push('Введите: А1 расстояние между ручьями');
                    } else if (order.distancestreams < 1.2) {
                        errors.push('А1 расстояние между ручьями: не может быть меньше 1.2');
                    }
                }

                if (order.amountrepetitionsrapport > 1) {
                    if (!order.distancerepetitionsrapport) {
                        errors.push('Введите: А2 расстояние между повторениями');
                    } else if (order.distancerepetitionsrapport < 1.2) {
                        errors.push('А2 расстояние между повторениями: не может быть меньше 1.2');
                    }
                }
            }

            if (!order.substratethickness) {
                errors.push('Введите: Толщину подложки');
            }

            if (!order.knifeheight) {
                errors.push('Введите: Высоту ножа');
            }

            // По свободному контуру
            if (order.typecircuit == TypeCircuits.Ameba) {
                if (!order.filename) {
                    errors.push('Необходимо приложить файл!');
                }

                /*  if (!order.knifewidth) {
                    errors.push('Введите: Ширину ножа');
                }*/
            }

            if (order.typeorder != 1) {
                // check knifewidth
                if (order.typecircuit < 3 && order.knifewidth > 0) {
                    const elementWidth = order.typecircuit == TypeCircuits.Circle ? order.diameter : order.width;
                    const knifewidthMinSize = (elementWidth + order.distancestreams) * order.amountstreams - order.distancestreams + 1.2;

                    if (order.knifewidth < knifewidthMinSize) {
                        errors.push(`Допустимая минимальная ширина ножа равна ${knifewidthMinSize} мм`);
                    }
                }

                // check knifelength
                if ((order.typefelling == TypeFellings.HalfRotation && order.knifelength > 0) ||
                    (order.typefelling == TypeFellings.Flat && order.shaftpitch > 0)
                ) {
                    const knifelengthValue = order.typefelling == TypeFellings.HalfRotation ? order.knifelength : order.shaftpitch;
                    const elementLength = order.typecircuit == TypeCircuits.Circle ? order.diameter : order.length;
                    const knifelengthMinSize = (elementLength + order.distancerepetitionsrapport) * order.amountrepetitionsrapport - order.distancerepetitionsrapport + 1.2;

                    if (knifelengthValue < knifelengthMinSize) {
                        errors.push(`Допустимая минимальная длина ножа равна ${knifelengthMinSize} мм`);
                    }
                }
            }

            errors.length > 0 ? reject(errors) : resolve();
        });
    }
}
