import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-privacy-text',
  templateUrl: './privacy-text.component.html',
  styleUrls: ['./privacy-text.component.css']
})
export class PrivacyTextComponent implements OnInit {

    @Output() close = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    print(): void {
        const innerContents = document.getElementById('printSection').innerHTML;
        const popupWinindow = window.open('', '_blank', 'width=1100,height=900,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html><head></head><body onload="window.print()">' + innerContents + '</html>');
        popupWinindow.document.close();
    }

}
