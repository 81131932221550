import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {AlertService} from 'ngx-alerts';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

    constructor(
        private alert: AlertService,
        private zone: NgZone,
        private router: Router
    ) { }

    handleError(error: Error) {

        if (error instanceof HttpErrorResponse) {
            console.error('http error', error);
            return;
        }

        console.error('error', error);

        this.zone.run(() => this.alert.danger('Извините, произошла ошибка'));

        const data = error instanceof Error ? this.transformError(error) : this.transformStrError(error);

        fetch(environment.devApiUrl + '/error-report', {
            method: 'POST',
            mode: 'no-cors',
            body: data
        });
    }

    transformError(error: Error) {
        return JSON.stringify({
            message: location.host + ' ' + error.message,
            details: error.stack,
            context: {
                url: this.router.url,
                local_storage: localStorage,
            }
        });
    }

    transformStrError(error) {
        return JSON.stringify({
            message: location.host + ' ' + error,
            details: error,
            context: {
                url: this.router.url,
                local_storage: localStorage,
            }
        });
    }
}
