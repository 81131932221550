import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contragent} from '../../../models/contragent';
import {User} from '../../../models/user';
import {AuthService} from '../../../services/auth.service';
import {Order} from '../../../models/order';
import {OrderService} from '../../../services/order.service';
import set = Reflect.set;
import {AlertService} from 'ngx-alerts';
import {TypeFellings} from '../../../models/enums/type-fellings.enum';
import {TypeCircuits} from '../../../models/enums/type-circuits.enum';

@Component({
    selector: 'app-order-form',
    templateUrl: './order-form.component.html',
    styleUrls: ['./order-form.component.css']
})
export class OrderFormComponent implements OnInit {

    showForm: boolean;

    askConfirmation: string;

    user: User;

    contragent: Contragent;

    delivery = 1;

    deliveryaddress: string;

    addressType = 'select';

    comments: string;

    success: boolean;

    error: string;

    orderId: string;

    @Input() order: Order;

    @Output() close = new EventEmitter();

    @Output() created = new EventEmitter();

    @Output() closeSuccessModal = new EventEmitter();

    constructor(
        private auth: AuthService,
        private orderService: OrderService,
        private alert: AlertService
    ) { }

    ngOnInit(): void {
        this.auth.getProfileData().subscribe((user: User) => {
            user.contragents = user.contragents.filter(contragent => contragent.calculation && contragent.orderinproduction);

            if (user.contragents.length == 0) {
                this.error = 'У вас нет Юр.лиц на которых можно оформить заказ!';
                return;
            }

            this.user = user;
            this.contragent = user.contragents[0];
            this.deliveryaddress = user.contragents[0].adressdelivery[0] || '';
            this.addressType = this.deliveryaddress ? 'select' : 'enter';

            if (this.order.typeorder != 1 &&
                this.order.typefelling == TypeFellings.HalfRotation &&
                this.order.knifelength > this.order.shaftpitch
            ) {
                this.askConfirmation = 'Длина ножа больше, чем раппорт магнитного вала!';
                return;
            }

            this.showForm = true;
        });
    }

    confirm() {
        this.askConfirmation = '';
        this.showForm = true;
    }

    onSelectContragent() {
        if (this.delivery == 1) {
            this.deliveryaddress = this.contragent.adressdelivery[0] || '';
            this.addressType = this.deliveryaddress ? 'select' : 'enter';
        } else {
            this.addressType = 'none';
        }
    }

    onSelectDeliveryMethod()
    {
        if (this.delivery == 1) {
            this.addressType = 'select';
            this.deliveryaddress = this.contragent.adressdelivery[0] || '';
            this.addressType = this.deliveryaddress ? 'select' : 'enter';
        } else {
            this.addressType = 'none';
            this.deliveryaddress = '';
        }
    }

    onSelectAddress() {
        if (this.deliveryaddress == '') {
            this.addressType = 'enter';
            setTimeout(function() {
                document.getElementById('another_address').focus();
            }, 300);
        }
    }

    async saveOrder() {
        if (this.delivery == 1 && this.deliveryaddress == '') {
            this.alert.warning('Введите адрес доставки');
            return;
        }

        let newOrder = new Order(this.order);

        if (this.order.typeorder == 1) {
            newOrder.uidorder1c = null;
            newOrder.orderid = null;
            newOrder.statusorder = '';
            newOrder.comments = (this.comments ? this.comments+"\n" : ''); // + 'Повтор заказа №' + this.order.orderid;

            newOrder.repeatorder = 1;
            newOrder.numberrepeatorder = this.order.orderid;
        }

        newOrder.typeorder = 1;
        newOrder.delivery = this.delivery;
        newOrder.deliveryaddress = this.deliveryaddress;
        newOrder.uidcontragent1c = this.contragent.uidcontragent1c;

        newOrder.shippingdate = await this.orderService.getShippingDate(newOrder.rushorder);

        this.orderService.addOrder(newOrder).subscribe(resp => {
            this.success = true;
            this.orderId = resp.orderid;
            this.created.emit(true);
        });
    }
}
