import {Component, OnInit} from '@angular/core';
import {NavbarService} from '../../../services/navbar.service';
import {ConfigService} from '../../../services/config.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    currentYear: number = new Date().getFullYear();

    constructor(
        public mobNavBar: NavbarService,
        public config: ConfigService
    ) { }

    ngOnInit(): void { }
}
