import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appInputPhone]'
})
export class InputPhoneDirective {

    private check(value: string) {
        return value.match(/^\+?[0-9()-]*$/i);
    }

    private run(oldValue, eventType: string) {
        setTimeout(() => {
            const currentValue = this.el.nativeElement.value;
            const start = this.el.nativeElement.selectionStart;

            if (currentValue !== '' && !this.check(currentValue)) {
                this.el.nativeElement.value = currentValue.replace(/[^+\d()-]/g, '');

                if (eventType === 'keydown') {
                    this.el.nativeElement.selectionStart = start - 1;
                    this.el.nativeElement.selectionEnd = start - 1;
                }
            }

        });
    }

    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.run(this.el.nativeElement.value, 'keydown');
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        this.run(null, 'paste');
    }

}
