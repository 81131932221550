export class BasicParameters {
    plandate: string;
    rushdate: string;
    widthtechfields: number;
    constructor(values: object = {}) {
        Object.assign(this, values);
    }
    getShippingDate(rushorder: number) {
        return rushorder ? this.rushdate : this.plandate;
    }
}
