import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit, OnDestroy {

    constructor(private renderer: Renderer2) { }

    ngOnInit(): void {
        this.renderer.addClass(document.body, 'homepage');
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'homepage');
    }

}
