import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../models/user';
import {FormGroupExtension, RxFormBuilder, ReactiveFormConfig} from '@rxweb/reactive-form-validators';
import {FormArray, FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {AlertService} from 'ngx-alerts';
import {Order} from '../../../models/order';
import {OrderService} from '../../../services/order.service';
import {ActivatedRoute} from '@angular/router';
import {Contragent} from '../../../models/contragent';
import {BasicParameters} from '../../../models/basic-parameters';
import {NgxSpinnerService} from 'ngx-spinner';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {CalculationResult} from '../../../models/calculation-result';
import {Element} from '../../../models/element';
import {TypeCircuits} from '../../../models/enums/type-circuits.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {TypeFellings} from '../../../models/enums/type-fellings.enum';
import isEqual from 'lodash.isequal';
import {KnifePreviewService} from './knife-preview.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {
    @HostBinding('class') classes = 'content content--lg m-b-64';
    calcForm: FormGroup;
    material: number;
    user: User;
    calculationResult: CalculationResult;
    checkoutOrder: Order;
    editOrderUuid: string;
    unableCalculate: object;
    storedCalculation: object;
    previousCalculation: object;
    knifePreviewParams: any[];
    knifePreviewError: string;
    showCalcScheme = false;

    @ViewChild('knife_preview_wrap')
    knifePreviewWrapEl: ElementRef;

    constructor(
        private alert: AlertService,
        private formBuilder: RxFormBuilder,
        private orderService: OrderService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private spinner: NgxSpinnerService,
        private knifePreviewService: KnifePreviewService
    ) { }

    ngOnInit() {
        if (this.editOrderUuid = this.route.snapshot.params.uidorder1c || '') {
            this.editOrder(this.editOrderUuid);
            return;
        }

        if (localStorage.getItem('calculation')) {
            this.storedCalculation = JSON.parse(localStorage.getItem('calculation'));
            return;
        }

        this.buildForm();
    }

    editOrder(orderUuid) {

        this.orderService.findOrder(orderUuid).subscribe((data: Order) => {
            if (!data) {
                this.alert.warning('Заказ/Расчет не найден');
                return;
            }

            let skipFields = ['typeorder','rushorder','unlimknife','perforation','microperforation', 'multipleedgeheights', 'multipleanglessharpening', 'edge2mm', 'manualknifewidth', 'radiusfillet' ];

            Object.keys(data).filter((k) => skipFields.indexOf(k) == -1).forEach(field => {
                data[field] = data[field] || null; // filled or null (empty or 0 ->to null replacement)
            });

            console.log(data);

            data.comments = data.speccomments;

            this.buildForm(new Order(data));

            if (data.typeorder == 0) {
                this.previousCalculation = new Order(data);

                this.calculationResult = {
                    orderid: data.orderid,
                    statusorder: data.statusorder,
                    sumorder: data.sumorder,
                    uidorder1c: data.uidorder1c,
                    errortext: ''
                }
            }
            // if it eq 1 - so it order, we can't update order.
        });
    }

    restoreCalculation() {
        this.buildForm(new Order(this.storedCalculation));
        localStorage.removeItem('calculation');
        this.storedCalculation = null;
    }

    forgetStoredCalculation() {
        localStorage.removeItem('calculation');
        this.storedCalculation = null;
        this.buildForm();
    }

    buildForm(order?: Order) {
        if (!order) {
            order = new Order({
                typefelling: 1,
                substratethickness: 50,
                anglesharpening: 90,
                knifeheight: 443,
                typecircuit: 2,
                elements: new Array<Element>()
            });
        }

        this.calcForm = this.formBuilder.formGroup(order);

        this.calcForm.get('typefelling').valueChanges.subscribe((v) => {
            this.resetControl(this.calcForm.controls.knifelength);
            this.resetControl(this.calcForm.controls.knifewidth);

            this.calcDistanceRepetitionsRapport(this.v.shaftpitch, this.v.length || this.v.diameter, this.v.amountrepetitionsrapport);
        });

        this.calcForm.get('typecircuit').valueChanges.subscribe((v) => {
            this.resetControl(this.calcForm.controls.width);
            this.resetControl(this.calcForm.controls.length);
            this.resetControl(this.calcForm.controls.radiusfillet);
            this.resetControl(this.calcForm.controls.diameter);
            this.resetControl(this.calcForm.controls.distancestreams);
            this.resetControl(this.calcForm.controls.distancerepetitionsrapport);

            this.resetControl(this.calcForm.controls.amountstreams, null);
            this.resetControl(this.calcForm.controls.amountrepetitionsrapport, null);

            if (v != TypeCircuits.Ameba) {
                this.calcForm.patchValue({
                    filename: '',
                    stringbinarydata: '',
                    multipleanglessharpening: 0,
                    multipleedgeheights: 0,
                    unlimknife: 0,
                    perforation: 0,
                    microperforation: 0
                });

                this.resetControl(this.calcForm.controls.perforationheight);
                this.resetControl(this.calcForm.controls.qtycut);
            }
        });

        this.calcForm.get('perforation').valueChanges.subscribe((length) =>  {
            this.resetControl(this.calcForm.controls.perforationheight);
        });

        this.calcForm.get('microperforation').valueChanges.subscribe((length) =>  {
            this.resetControl(this.calcForm.controls.qtycut);
        });

        this.calcForm.get('length').valueChanges.subscribe((length) =>  {
            this.calcDistanceRepetitionsRapport(this.v.shaftpitch, length, this.v.amountrepetitionsrapport);
        });

        this.calcForm.get('diameter').valueChanges.subscribe((diameter) =>  {
            this.calcDistanceRepetitionsRapport(this.v.shaftpitch, diameter, this.v.amountrepetitionsrapport);
        });

        this.calcForm.get('shaftpitch').valueChanges.subscribe((shaftpitch) =>  {
            this.calcDistanceRepetitionsRapport(shaftpitch, this.v.length || this.v.diameter, this.v.amountrepetitionsrapport);
        });

        this.calcForm.get('amountrepetitionsrapport').valueChanges.subscribe((amountrepetitionsrapport) =>  {
            this.calcDistanceRepetitionsRapport(this.v.shaftpitch, this.v.length || this.v.diameter, amountrepetitionsrapport);
        });

        this.calcForm.get('amountstreams').valueChanges.subscribe((amountstreams) =>  {
            if (amountstreams == 1) {
                this.calcForm.get('distancestreams').setValue(null, { emitEvent: false });
            }
        });


        this.calcForm.valueChanges.subscribe((v) => {
            this.renderKnifePreview(v);
        });

        if (order.orderid) {
            setTimeout(() => {
                this.renderKnifePreview(order);
            }, 1000);
        }
    }

    renderKnifePreview(v) {
        let area = {
            width: this.knifePreviewWrapEl.nativeElement.offsetWidth - 20,
            height: this.knifePreviewWrapEl.nativeElement.offsetHeight - 20,
        };

        this.knifePreviewParams = this.knifePreviewService.doCalculate(v, area);
        this.knifePreviewError = this.knifePreviewService.error;
    }

    resetControl(control, val = null) {
        control.reset(val);
        control.markAsUntouched();
        control.markAsPristine();
    }

    get rxForm(): FormGroupExtension{
        return this.calcForm as FormGroupExtension;
    }

    get f() {
        return this.rxForm.controls;
    }

    get v() {
        return this.rxForm.value;
    }

    get felements() {
        return <FormArray>this.calcForm.get('elements');
    }

    addElement() {
        this.felements.push(this.formBuilder.formGroup(Element));
    }

    removeElement(index) {
        this.felements.removeAt(index);
    }

    changeAngle(val: number = null) {
        this.calcForm.get('anglesharpening').setValue(val);
        this.calcForm.get('anglesharpening').markAsDirty();

        if (val == null) {
            document.getElementById('js-calc-grinding-angle').focus();
        }
    }

    setRushOrderAndShowPrice() {
        this.calcForm.markAllAsTouched();

        if (this.rxForm.invalid) {
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.calcForm.controls.rushorder.setValue(!this.v.rushorder);
        this.showPrice();
    }

    check() {

        if (this.rxForm.invalid) {
            this.rxForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            console.log(this.rxForm.getErrorSummary(false));
            return false;
        }

        // Рассчет а2 Прямоуг,Круг. все кроме свободного контура
        // для Ротации и Плоского - т.е. кроме полуротации
        if (this.v.typecircuit < 3 && this.v.typefelling != 2) {
            if (this.validateDistanceRepetitionsRapport() == false) {
                return false;
            }
        }

        return true;
    }

    calcDistanceRepetitionsRapport(shaftpitch, length, amountrepetitionsrapport) {

        if (this.v.typecircuit == TypeCircuits.Ameba ||
            this.v.typefelling == TypeFellings.HalfRotation ||
            this.v.typefelling == TypeFellings.Flat) {
            if (amountrepetitionsrapport == 1) {
                this.calcForm.get('distancerepetitionsrapport').setValue(null, {emitEvent: false});
            }

            return;
        }

        let result = (shaftpitch && length && amountrepetitionsrapport > 1)
            ? ((shaftpitch / amountrepetitionsrapport) - length)
            : null;

        this.calcForm.get('distancerepetitionsrapport').setValue(
            result ? this.roundAndFix(result, 3) : null,
            { emitEvent: false }
        );
    }

    roundAndFix(n, d) {
        var m = Math.pow (10, d);
        return Math.round (n * m) / m;
    }

    validateDistanceRepetitionsRapport() {
        if (this.v.distancerepetitionsrapport && this.v.distancerepetitionsrapport < 1.2) {
            this.alert.danger('А2 расстояние между повторениями не может быть меньше 1.2');
            return false;
        }

        return true;
    }

    getDirty(a, b): string[] {
        return Object.keys(a).filter(k => {
            return !isEqual(a[k], b[k] ?? null);
        });
    }

    async send(after: (x) => any) {
        // prep order
        let order = new Order(this.calcForm.value);

        if (this.calculationResult && this.formHasImportantChanges()) {
            order.uidorder1c = this.calculationResult.uidorder1c;
        }

        this.calculationResult = null;
        this.previousCalculation = this.calcForm.value;
        this.calcForm.markAsPristine();

        // send request
        let cb = this.catchServer1cUnavailableError.bind(this);

        this.calcForm.value.shippingdate = await this.orderService.getShippingDate(this.v.rushorder, cb);

        this.orderService.addOrder(order).subscribe(after, cb);
    }

    formHasImportantChanges() {
        if (!this.previousCalculation) {
            return true;
        }

        let notImportantProps = [
            'nameorder', 'typefelling', 'substratethickness', 'knifeheight', 'laserhardening','antiadhesioncoating', 'rushorder',
            'multipleanglessharpening', 'multipleedgeheights', 'unlimknife',
            'perforation', 'perforationheight',
            'microperforation', 'qtycut',
            'shaftpitch', 'knifewidth', 'knifelength', 'distancestreams', 'distancerepetitionsrapport',
            'shippingdate', 'comments'
        ];

        let dirty = this.getDirty(this.calcForm.value, this.previousCalculation);

        console.log('dirty', dirty, this.calcForm.value, this.previousCalculation);

        // if every changed prop is not important
        return dirty.every(prop => notImportantProps.includes(prop));
    }

    catchServer1cUnavailableError(err: HttpErrorResponse) {
        if (err.error && err.error.errors && err.error.errors.some(apiError => apiError.code == 'server_1c_unavailable')) {
            this.unableCalculate = this.calcForm.value;
        }
    }

    rememberCalculation() {
        localStorage.setItem('calculation', JSON.stringify(this.calcForm.value));
        this.alert.success('Мы запомнили параметры расчета, вы сможете их восстановить позже');
        this.unableCalculate = null;
    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {

            if (event.target.files[0].name.match(/\.(exe|com|bat|cmd|msi|js|hta)+$/i)) {
                this.alert.danger('Неверный формат файла');
                this.calcForm.patchValue({
                    filename: '',
                    stringbinarydata: ''
                });
                return;
            }

            this.calcForm.patchValue({
                filename: event.target.files[0].name
            });

            const [file] = event.target.files;

            reader.onload = () => {
                let base64 = reader.result.toString().split('base64,', 2)[1];

                this.calcForm.patchValue({
                    stringbinarydata: base64
                });
            };

            reader.readAsDataURL(file);
        } else {
            this.calcForm.patchValue({
                filename: '',
                stringbinarydata: ''
            });
        }
    }

    showPrice() {
        if (this.check() === false) {
            return;
        }

        // if ameba
        if (this.v.typecircuit == 3 && this.v.elements.length == 0) {
            this.alert.danger('Для оценки стоимости добавьте элементы (согласно правилам)');
            return;
        }

        this.send((resp: CalculationResult) => {
            this.calculationResult = resp;
        });
    }

    showCheckoutForm() {
        if (this.check() === false) {
            return;
        }

        this.orderService.canCheckout(this.v).then(
            () => {
                this.startCheckout();
            },
            (errors: []) => {
                errors.reverse().forEach((err) => { this.alert.danger(err); });
            }
        );
    }

    startCheckout() {
        if (this.calcForm.pristine && this.calculationResult) {
            this.checkoutOrder = this.v;
            this.checkoutOrder.uidorder1c = this.calculationResult.uidorder1c;

            return; // show checkout form
        }

        this.send((resp: CalculationResult) => {
            this.calculationResult = resp;

            this.checkoutOrder = this.v;
            this.checkoutOrder.uidorder1c = this.calculationResult.uidorder1c;
        });
    }
}
