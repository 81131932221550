import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-whoops',
  templateUrl: './whoops.component.html',
  styleUrls: ['./whoops.component.css']
})
export class WhoopsComponent implements OnInit {

    @Input() whoopsMsg: string;

    constructor() { }

    ngOnInit(): void {
    }

}
