import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
    cache = {};

    constructor() { }

    has(key) {
        return key in this.cache;
    }

    set(key, value) {
        this.cache[key] = value;
    }

    get(key, defaultValue = null) {
        return this.cache[key] || defaultValue;
    }

    clear() {
        this.cache = {};
    }
}
