<div *ngIf="whoopsMsg" class="whoops flex j-c-center a-i-center">
    <div class="m-r-32"><img src="/assets/img/whoops_emoj.png" alt="whoops"></div>
    <div class="m-l-32">
        <div class="text-level-1 text-level-1--no-margin">Упс...</div>
        <div class="text-level-2">Произошла ошибка</div>

        <div class="info-block">
            <div class="info-block__caption">{{ whoopsMsg }}</div>
            <div class="info-block__text">
                <p>&nbsp;</p>
                <p>Возможные решения:</p>
                <p>немного подождать и нажать F5</p>
            </div>
        </div>
    </div>
</div>
