<h1 class="text-level-1 t-a-center m-b-on-lg-48" xmlns="http://www.w3.org/1999/html">Регистрация нового пользователя</h1>

<form [formGroup]="registrationForm" (ngSubmit)="register()" *ngIf="!success">
    <div class="flex flex-wrap m-t-on-lg-48">
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="name-registration" type="text" formControlName="first_name" />
            <label class="form-label" for="name-registration">
                Имя<span class="danger-color">*</span>
                <span class="err-msg">{{ f.first_name['errorMessage'] }}</span>
            </label>
        </div>
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="surname-registration" type="text" formControlName="last_name"/>
            <label class="form-label" for="surname-registration">
                Фамилия<span class="danger-color">*</span>
                <span class="err-msg">{{ f.last_name['errorMessage'] }}</span>
            </label>
        </div>
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="email-registration" type="email" formControlName="email" />
            <label class="form-label" for="email-registration">
                E-mail<span class="danger-color">*</span>
                <span class="err-msg">{{ f.email['errorMessage'] }}</span>
            </label>
        </div>
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="tel-registration" type="tel" formControlName="telefon" appInputPhone />
            <label class="form-label" for="tel-registration">
                Телефон<span class="danger-color">*</span>
                <span class="err-msg">{{ f.telefon['errorMessage'] }}</span>
            </label>
        </div>
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="printing-name-registration" type="text" formControlName="typography" />
            <label class="form-label" for="printing-name-registration">Типография</label>
        </div>
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="inn-registration" type="text" formControlName="inn" />
            <label class="form-label" for="inn-registration">ИНН<span class="danger-color">*</span></label>
        </div>
    </div>
    <div class="flex flex-wrap">
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="new-password-registration" type="password" formControlName="password" appInputPassword />
            <label class="form-label" for="new-password-registration">
                Пароль<span class="danger-color">*</span>
                <span class="err-msg">{{ f.password['errorMessage'] }}</span>
            </label>
        </div>
        <div class="form-group form-group--1x2">
            <input class="form-element form-element--filled" id="new-password-repeat-registration" type="password" formControlName="password_confirm" appInputPassword />
            <label class="form-label" for="new-password-repeat-registration">
                Повторите пароль<span class="danger-color">*</span>
                <span class="err-msg">{{ f.password_confirm['errorMessage'] }}</span>
            </label>
        </div>
    </div>
    <div class="form-group">
        <div class="checkbox">
            <input class="checkbox__input" id="agreement-write-to-us" type="checkbox" value="1" formControlName="agreement" />
            <label class="checkbox__label" for="agreement-write-to-us">
                <svg class="svg-icon" viewBox="0 0 1 1" width="10" height="10">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </label>
            <label class="checkbox__caption" for="agreement-write-to-us">
                Согласие на обработку персональных данных.
                <span class="danger-color">*</span>
                <span class="err-msg">{{ f.agreement['errorMessage'] }}</span>
            </label>
        </div>
        <div class="full-width">
            <a (click)="showPrivacyPolicy = true" class="read-privacy-text---link">Пользовательское соглашение ООО ДжастКат</a>
        </div>
    </div>
    <div class="flex j-c-center">
        <button class="btn btn--main btn--lg m-t-on-lg-24" type="submit">Зарегистрироваться</button>
    </div>
</form>


<div class="t-a-center" *ngIf="success">
    <h1 class="text-level-1">Почти готово</h1>
    <div class="cheque-mark m-b-on-lg-48">
        <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23">
            <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
        </svg>
    </div>
    <p class="m-t--24">
        Подтвердите почту, чтобы активировать аккаунт. <br>
        Мы отправили Вам email сообщение, следуйте инструкциям в нем.<br>
        Если его нет - проверьте папку Спам.
    </p>
</div>

<app-privacy-text *ngIf="showPrivacyPolicy" (close)="showPrivacyPolicy = false"></app-privacy-text>
