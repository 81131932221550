<header class="header">
    <button class="toggle" type="button" (click)="mobileNavbar.toggleMainNav()">
        <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23" *ngIf="mobileNavbar.mobMainNavHidden">
            <use xlink:href="/assets/img/sprite.svg#menu"></use>
        </svg>
        <svg class="svg-icon" viewBox="0 0 1 1" width="21" height="21" *ngIf="!mobileNavbar.mobMainNavHidden">
            <use xlink:href="/assets/img/sprite.svg#cross"></use>
        </svg>
    </button>

    <div class="inline-flex a-i-center header__contacts">
        <a class="inline-flex a-i-center m-r-24 text-link" href="tel:+{{ config.phoneOnlyDigits }}">
            <svg class="svg-icon main-coмиlor" viewBox="0 0 1 1" width="18" height="18">
                <use xlink:href="/assets/img/sprite.svg#phone-one-ribbon"></use>
            </svg>
            <span class="m-l-8 f-s-18 f-w-500">{{ config.phone }}</span>
        </a>
        <button class="btn btn--main" type="button" routerLink="/contacts">
            Написать нам
        </button>

        <!-- Переключатель языка -->
        <div class="language-switcher m-l-24">
            <span class="language-text" (click)="setLanguage('RU')">RU</span>
            <span class="language-text"> | </span>
            <span class="language-text" (click)="setLanguage('ENG')">EN</span>
        </div>
    </div>

    <a class="logo" routerLink="/">
        <img class="logo__img" src="/assets/img/sk_logo.svg" alt="SK Logo" title="SK Logo" width="85" height="28">
        <img class="logo__img" src="/assets/img/logo.png" alt="JustCut logo" title="JustCut" width="85" height="28">
    </a>

    <div class="user-nav" *ngIf="!auth.isLoggedIn()" [class.user-nav--hidden]="mobileNavbar.mobUserNavHidden">
        <img src="/assets/img/cat.png" alt="Additional Image" style="width: 60px; height: 50px" class="additional-img">
        <a class="btn btn--outlined" routerLink="/login">Войти</a>
        <a class="btn btn--main" routerLink="/register">Регистрация</a>
    </div>

    <div class="user-nav" *ngIf="auth.isLoggedIn()" [class.user-nav--hidden]="mobileNavbar.mobUserNavHidden">
        <div class="status-wrapper" *ngIf="primaryContragent">
            <img src="/assets/img/cat.png" alt="Additional Image" style="width: 60px; height: 50px" class="additional-img">
            <a class="status-indicator green" [routerLink]="['/user-area']" [queryParams]="{ tab: 2 }">
                {{ getTotalDebt() > 0 ? getTotalDebt() : '—' }}
            </a>
            <span class="separator">/</span>
            <a class="status-indicator red" [routerLink]="['/user-area']" [queryParams]="{ tab: 2 }">
                {{ getTotalArrears() > 0 ? getTotalArrears() : '—' }}
            </a>
        </div>
        <a class="inline-flex a-i-center user-nav__user" routerLink="/user-area">
            <svg class="svg-icon" viewBox="0 0 1 1" width="21" height="21">
                <use xlink:href="/assets/img/sprite.svg#user-in-circle"></use>
            </svg>
            <span>{{ auth.userValue.first_name }} {{ auth.userValue.last_name }}</span>
        </a>
        <a class="btn btn--main hidden-on-lg" routerLink="/user-area">Личный кабинет</a>
        <a class="btn btn--outlined" (click)="auth.logout();">Выйти</a>
    </div>

    <button class="toggle" type="button" (click)="mobileNavbar.toggleUserNav()">
        <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23" *ngIf="mobileNavbar.mobUserNavHidden">
            <use xlink:href="/assets/img/sprite.svg#user-in-circle"></use>
        </svg>
        <svg class="svg-icon" viewBox="0 0 1 1" width="21" height="21" *ngIf="!mobileNavbar.mobUserNavHidden">
            <use xlink:href="/assets/img/sprite.svg#cross"></use>
        </svg>
    </button>
</header>
