<div class="cover">
    <img class="cover__img"
         srcset="/assets/img/justcut_homepage_img_d.jpg, /assets/img/justcut_homepage_img_x2.jpg 1.5x, /assets/img/justcut_homepage_img_x2.jpg 2x"
         src="/assets/img/justcut_homepage_img_d.jpg"
         alt="Бренд JustCut"/>
</div>
<div class="content">
    <h1 class="text-level-1 text-level-1--no-margin-full">
        Компания JustCut &ndash; ваш надежный партнер по гибким высечным ножам. И всему, что связано с высечкой. <br>И даже чуть больше.
    </h1>
    <p>В 2004 году все началось с мечты одного человека создать производство гибких ножей. К ней добавились многолетние исследования рынка и отработка технологии высокоточной обработки металла плюс понимание сопутствующих производству ножей процессов.</p>
    <p>В 2016-17 гг. в Москве собственными силами единомышленников сформирована производственная площадка со всем необходимым оборудованием. Проведены первые испытания продукции в дружественных типографиях.</p>
    <p>В январе 2018 года российская компания JustCut официально поставляет гибкие высечные ножи в типографии страны.</p>
    <p>В 2019 г компания удваивает мощности, выходит на рынки ближайшего зарубежья.</p>
    <p>В 2020 г в арсенале JustCut появляется микроперфорация и лазерная закалка RLL ножей собственной разработки.</p>
    <p>В перспективе - антиадгезионное покрытие RAA, и, конечно, другие технологические новинки.</p>
    <p>Мы получили знания о высечке с самого &laquo;0&raquo;. И готовы делиться с вами. Помогать и развиваться вместе &ndash; простой, но очень уверенный девиз для всех нас.</p>
</div>
