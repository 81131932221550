<div class="modal-overlay is-active" *ngIf="showForm">

    <div class="modal" *ngIf="!success">
        <h3 class="modal__header">{{ order.typeorder ? 'Повтор заказа' : 'Новый заказ' }}</h3>

        <div class="modal__content wide--w-540">
            <div class="flex flex-wrap">
                <div class="form-group">
                    <div class="select select--filled select--form select--sm">
                        <select class="select__inner" [(ngModel)]="contragent" (ngModelChange)="onSelectContragent()">
                            <option *ngFor="let cntrg of user.contragents" [ngValue]="cntrg">
                                {{ cntrg.namejur }}
                            </option>
                        </select>
                    </div>
                    <label class="form-label">
                        Юридическое лицо<span class="danger-color">*</span>
                    </label>
                </div>

                <div class="form-group hidden" *ngIf="contragent">
                    <div class="inline-flex">
                        <div class="radio">
                            <input class="radio__input" id="type-delivery-1" type="radio" [(ngModel)]="delivery" [value]="1" (ngModelChange)="onSelectDeliveryMethod()">
                            <label class="radio__label" for="type-delivery-1"></label>
                            <label class="radio__caption" for="type-delivery-1">Доставка</label>
                        </div>
                        <div class="radio m-l-16">
                            <input class="radio__input" id="type-delivery-0" type="radio" [(ngModel)]="delivery" [value]="0" (ngModelChange)="onSelectDeliveryMethod()">
                            <label class="radio__label" for="type-delivery-0"></label>
                            <label class="radio__caption" for="type-delivery-0">Самовывоз</label>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="contragent && delivery == 1">

                    <div class="select select--filled select--form select--sm" *ngIf="addressType == 'select'">
                        <select class="select__inner" [(ngModel)]="deliveryaddress" (ngModelChange)="onSelectAddress()">
                            <option *ngFor="let adress of contragent.adressdelivery" [value]="adress">
                                {{ adress }}
                            </option>
                            <option value="">... ввести другой адрес</option>
                        </select>
                    </div>

                    <input id="another_address" *ngIf="addressType == 'enter'" class="form-element form-element--sm form-element--filled" type="text" [(ngModel)]="deliveryaddress">

                    <label class="form-label">
                        Адрес доставки<span class="danger-color">*</span>
                    </label>
                </div>

                <!-- for order allow comments field -->
                <div class="form-group" *ngIf="order.typeorder == 1">
                    <p class="text-level-3 m-b-8 m-t-16">Комментарий:</p>
                    <textarea class="form-element form-element--sm full-width" id="order-wishes" name="wishes" rows="1" placeholder="Опишите здесь все свои пожелания и уточнения по заказу" [(ngModel)]="comments"></textarea>
                </div>

            </div>
            <div class="flex j-c-center">
                <button class="btn btn--main btn--lg" type="button" (click)="saveOrder()">Оформить новый заказ</button>
            </div>
        </div>

        <button class="modal__close" type="button" (click)="this.close.emit(true)">
            <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
            </svg>
        </button>
    </div>

    <div class="modal modal--sm t-a-center" *ngIf="success">
        <div class="modal__wrapper">
            <div class="cheque-mark">
                <svg class="svg-icon" viewBox="0 0 1 1" width="23" height="23">
                    <use xlink:href="/assets/img/sprite.svg#cheque-mark"></use>
                </svg>
            </div>
            <h3 class="modal__header">Заказ создан!</h3>
            <div class="modal__content">
                <p>
                    Ваш заказ успешно создан и добавлен в таблицу заказов.
                </p>
                <a class="btn btn--main btn--md f-w-700 m-t-24" routerLink="/orders" (click)="this.closeSuccessModal.emit(true)">
                    Перейти к заказам
                </a>
            </div>
        </div>
        <button class="modal__close" type="button" (click)="this.closeSuccessModal.emit(true)">
            <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
            </svg>
        </button>
    </div>

</div>

<div class="modal-overlay is-active" *ngIf="error">
    <div class="modal modal--sm t-a-center">
        <div class="modal__wrapper">
            <h3 class="modal__header">Ошибка!</h3>
            <div class="modal__content">
                <p>{{ error }}</p>
            </div>
        </div>
        <button class="modal__close" type="button" (click)="this.close.emit(true)">
            <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
            </svg>
        </button>
    </div>
</div>

<div class="modal-overlay is-active" *ngIf="askConfirmation">
    <div class="modal t-a-center">
        <div class="modal__wrapper">
            <div class="modal__content">
                <div class="m-b-16">
                    <p><b>{{ askConfirmation }}</b></p>
                    <p>&nbsp;</p>
                    <p>Вы уверенны, что хотите продолжить формирование заказа?</p>
                </div>
                <a class="btn btn--main btn--lg m-t-24" (click)="this.confirm()">
                    Продолжить
                </a>
            </div>
        </div>
        <button class="modal__close" type="button" (click)="this.close.emit(true)">
            <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
            </svg>
        </button>
    </div>
</div>
