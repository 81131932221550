import {Inject, Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {AlertService} from 'ngx-alerts';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    url: string;
    apiurl: string;


    constructor(
        private alerts: AlertService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private auth: AuthService
    ) {
        const mode = location.host.match(/^(loc|dev)/) ? 'dev' : 'prod';
        this.apiurl = mode === 'prod' ? environment.apiUrl : environment.devApiUrl;
        console.log('mode', mode);
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.url = request.method + ' ' + request.url;
        this.spinner.hide().finally(() => {
            this.startLoader();
        });

        return next
            .handle(this.configureRequest(request))
            .pipe(
                catchError(this.handleErrors.bind(this)),
                tap(this.stopLoader.bind(this))
            );
    }

    configureRequest(request: HttpRequest<unknown>): HttpRequest<any> {
        const token = this.auth.userValue ? this.auth.userValue.token : null;

        return request.clone({
            url: `${this.apiurl}${request.url}`,
            setHeaders: token ? { Authorization: `Bearer ${token}` } : {}
        });
    }

    handleErrors(err: HttpErrorResponse) {
        if (err.status === 401) {
            this.auth.logout();
        }

        // Server-side errors
        if (err.error && err.error.errors) {
            err.error.errors.forEach(apiError => {
                this.alerts.danger(apiError.message);
            });
        } else {
            this.alerts.warning(err.message);
            this.alerts.danger('Произошла неизвестная ошибка');
        }

        this.spinner.hide();

        return throwError(err);
    }

    startLoader() {
        this.spinner.show();
    }

    stopLoader(resp: any) {
        if (resp instanceof HttpResponse) {
            this.spinner.hide();
        }
    }
}
