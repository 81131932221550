import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {User} from '../models/user';
import {Router} from '@angular/router';
import {NewUser} from '../models/new-user';
import {AlertService} from 'ngx-alerts';
import {CacheService} from './cache.service';
import {Contragent} from '../models/contragent';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private alert: AlertService,
        private cache: CacheService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    setUser(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
    }

    login(data: object): Observable<any> {
        return this.http.post('/login', data)
            .pipe(map((user: User) => {
                this.setUser(user);
                return user;
            }));
    }

    logout() {
        localStorage.removeItem('user');
        this.cache.clear();
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    register(user: NewUser): Observable<any>  {
        return this.http.post('/register', user);
    }

    isLoggedIn(): boolean {
        return this.userValue !== null;
    }

    getProfileData(): Observable<User> {
        if (this.cache.has('user_profile')) {
            return of(this.cache.get('user_profile'));
        }

        return this.http.get<User>('/profile').pipe(
            tap(data => {
                this.cache.set('user_profile', data);
            })
        );;
    }

    updateProfileData(user: User): Observable<any> {
        this.cache.clear();

        return this.http.post('/profile', user);
    }

    addContragent(data): Observable<any> {
        return this.http.post('/contragent', data);
    }

    deleteContragent(data): Observable<any> {
        return this.http.post('/contragent/delete', data);
    }

    updatePassword(data): Observable<any> {
        return this.http.post('/profile/password', data);
    }

    sendActivationCode(email: string): Observable<any> {
        return this.http.post('/send_activation_code', {email: email});
    }

    activate(user_id: string, code: string): Observable<any> {
        return this.http.post('/activate', {user_id: user_id, code: code})
            .pipe(map((user: User) => {
                this.setUser(user);
                return user;
            }));
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post('/password/forgot', {email: email});
    }

    resetPassword(data: object): Observable<any> {
        return this.http.post('/password/reset', data);
    }

    checkToken() {
        if (!this.userValue) {
            return false;
        }

        const jwtToken = JSON.parse(atob(this.userValue.token.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);

        if (expires.getTime() < Date.now()) {
            localStorage.removeItem('user');
            this.cache.clear();
            this.userSubject.next(null);
            return false;
        }

        return true;
    }
}
