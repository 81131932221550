import {Component, HostBinding, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import { FormGroup } from '@angular/forms';
import {
    FormGroupExtension,
    ReactiveFormConfig,
    RxFormBuilder,
    RxwebValidators
} from '@rxweb/reactive-form-validators';
import {AlertService} from 'ngx-alerts';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    @HostBinding('class') classes = 'content content--xsm a-s-center';
    loginForm: FormGroup;

    constructor(
        private auth: AuthService,
        private alert: AlertService,
        private formBuilder: RxFormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [
                RxwebValidators.required(),
                RxwebValidators.email()
            ]],
            password: ['', [
                RxwebValidators.required()
            ]]
        });
    }

    get rxForm(): FormGroupExtension{
        return this.loginForm as FormGroupExtension;
    }

    login() {
        if (this.rxForm.invalid) {
            this.rxForm.markAllAsTouched();
            this.alert.warning(ReactiveFormConfig.get('globalErrorMsg'));
            return;
        }

        this.auth.login(this.rxForm.value).subscribe(resp => {
            this.router.navigate(['/calculator']);
        });
    }
}
