<div class="modal-overlay with-modal-big is-active">

    <div class="modal m-t-32">
        <p class="t-a-center"><strong>Пользователь сайта/Покупательское соглашение</strong></p>

        <div id="printSection" class="modal__content content">
            <p>
                Настоящее Соглашение определяет условия использования Пользователями
                сайта/Покупателями материалов и сервисов сайта    <a target="_blank" href="https://www.justcut.ru/">www.justcut.ru</a>&nbsp;(<a target="_blank" href="https://джасткат.рф/">джасткат.рф</a>) (далее — «Сайт»), и
                рассматривается Администрацией сайта/Поставщиком как публичная оферта в
                соответствии со ст. 437 Гражданского кодекса Российской Федерации.
            </p>
            <p>
                <strong>Термины</strong>
            </p>
            <p>
                <strong>Сайт </strong>
                – <a target="_blank" href="https://www.justcut.ru/">www.justcut.ru</a>&nbsp;(<a target="_blank" href="https://джасткат.рф/">джасткат.рф</a>) – Интернет-сайт,
                принадлежащий Администрации сайта/Поставщику, расположенный на сервере в г.
                Москва и имеющий адрес в сети Интернет    <a target="_blank" href="https://www.justcut.ru/">www.justcut.ru</a>&nbsp;(<a target="_blank" href="https://джасткат.рф/">джасткат.рф</a>). на котором любой
                Пользователь сайта/Покупатель может ознакомиться с технологиями,
                возможностями, стандартами качества, осуществить расчет стоимости заявки,
                отправить Заявку через Личный кабинет Сайта или сделать Заявку на
                электронную почту: <a href="mailto:cut@justcut.ru">cut@justcut.ru</a>.
            </p>
            <p>
                на изготовление продукции.
            </p>
            <p>
                <strong>Пользователь сайта/Покупатель </strong>
                – полностью дееспособное физическое лицо, либо юридическое лицо в лице
                представителя, размещающее Заявку на сайте, либо указанное в качестве
                получателя Продукции, заказанной на сайте.
            </p>
            <p>
                <strong>Администрация сайта/Поставщик – </strong>
                ООО «ДжастКат», производитель гибких вырубных штампов (ножов) (далее –
                продукции).
            </p>
            <p>
                <strong>Личный кабинет </strong>
                – раздел сайта, доступный Пользователю сайта/Покупателю после регистрации,
                посредством которого Пользователь сайта/Покупатель осуществляет управление
                своей учетной записью (аккаунтом), в том числе с возможностью обмена
                документами, расчетом заказов, размещения заявок в производство,
                отслеживания истории заявок, внесение и просмотр данных по использованию
                заказанной продукции, просмотр условий заключенных договоров и состояния
                взаиморасчетов между компаниями.
            </p>
            <p>
                <strong>Заявка </strong>
                – запрос<strong> </strong>Пользователя сайта/Покупателя<strong> </strong>
                на изготовление и поставку продукции у Администрации сайта/Поставщика,
                сформированной в личном кабинете, отправленный посредством сети Интернет, а
                также на доставку продукции по указанному в заявке адресу, на выбор
                Пользователя сайта/Покупателя при формировании заявки: курьерской службой,
                силами Администрации сайта/Поставщика или путем самовывоза Продукции, по
                адресу, согласованному сторонами.
            </p>
            <p>
                <strong>Продукция</strong>
                – объект изготовления, представляющий собой гибкие вырубные штампы (ножи).
            </p>
            <p>
                <strong>Верификация </strong>
                – процедура, производимая менеджером Администрации сайта/Поставщика и
                позволяющая установить связку между Пользователем сайта/Покупателем и
                юридическим лицом, от лица которого этот Пользователь сайта/Покупатель
                будет действовать на сайте.
            </p>
            <p>
                <strong>Калькулятор</strong>
                – сервис Сайта, позволяющий Пользователем сайта/Покупателем самостоятельно
                осуществить расчет стоимости заявки. Произведенный при помощи калькулятора
                расчет действителен в течение 10 (десяти) календарных дней и предоплате в
                размере 100% (если иное не установлено договором). Порядок расчета цен,
                формируемых на сайте, может изменяться Администрацией сайта/Поставщиком в
                одностороннем порядке без уведомления Пользователя сайта/Покупателя. Данные
                изменения размещаются на сайте и вступают в силу немедленно после
                размещения. При этом Стороны руководствуются тем, что новый порядок расчета
                цен не распространяется на продукцию, уже оплаченную Пользователем
                сайта/Покупателем.
            </p>
            <p>
                <strong>1.Общие условия</strong>
            </p>
            <p>
                1.1. Использование материалов и сервисов Сайта регулируется нормами
                действующего законодательства Российской Федерации.
            </p>
            <p>
                1.2. Настоящее Соглашение является публичной офертой. Получая доступ к
                материалам Сайта Пользователь сайта/Покупатель считается присоединившимся к
                настоящему Соглашению.
            </p>
            <p>
                1.3. Администрация сайта/Поставщик вправе в любое время в одностороннем
                порядке изменять условия настоящего Соглашения. Такие изменения вступают в
                силу с момента размещения новой версии Соглашения на сайте. При несогласии
                Пользователя сайта/Покупателя с внесенными изменениями он обязан отказаться
                от доступа к Сайту, прекратить использование материалов и сервисов Сайта.
            </p>
            <p>
                <strong>2. </strong>
                <strong>Регистрация на сайте и верификация</strong>
            </p>
            <p>
                2.1. Регистрация на Сайте осуществляется по адресу    <a target="_blank" href="https://www.justcut.ru/">www.justcut.ru</a>&nbsp;(<a target="_blank" href="https://джасткат.рф/">джасткат.рф</a>) .
            </p>
            <p>
                2.2. Регистрация на Сайте является обязательным условием для оформления
                Заявки.
            </p>
            <p>
                2.3. Администрация сайта/Поставщик не несет ответственности за точность и
                правильность информации, предоставляемой Пользователем сайта/Покупателем
                при регистрации.
            </p>
            <p>
                2.4. Для регистрации на Сайте и/или оформления Заявки Пользователю
                сайта/Покупателю необходимо указать свои данные, в том числе: ФИО,
                наименование Типографии, чьим представителем он является, e-mail,
                контактный телефон, после чего пройти по ссылке из письма, присланного на
                ранее указанный почтовый ящик.
            </p>
            <p>
                2.5. Пользователь сайта/Покупатель, зарегистрировавшийся на сайте, после
                верификации получает доступ к личному кабинету. При регистрации личного
                кабинета на сайте Администрации сайта/Поставщика только Пользователь
                сайта/Покупатель знает свои учетные данные (логин и пароль) для пользования
                личным кабинетом и ответственность при разглашении этих данных несет
                самостоятельно. Администрация сайта/Поставщик не хранит у себя учетные
                данные Пользователей сайта/Покупателей.
            </p>
            <p>
                Верификация Пользователя сайта/Покупателя позволяет избежать
                несанкционированных действий третьих лиц от имени Пользователя
                сайта/Покупателя и открывает доступ к дополнительным сервисам Сайта.
                Передача Пользователем сайта/Покупателем логина и пароля третьим лицам
                запрещена. Пользователь сайта/Покупатель несет ответственность за передачу
                учетных данных третьим лицам.
            </p>
            <p>
                В случае возникновения у Пользователя сайта/Покупателя подозрений
                относительно безопасности его логина и пароля или возможности их
                несанкционированного использования третьими лицами, Пользователь
                сайта/Покупатель обязуется незамедлительно уведомить об этом Администрацию
                сайта/Поставщика, направив соответствующее электронное сообщение из личного
                кабинета или электронное письмо по адресу:    <a href="mailto:cut@justcut.ru">cut@justcut.ru</a>.
            </p>
            <p>
                2.6. Администрация сайта/Поставщик подтверждает и гарантирует Пользователю
                сайта/Покупателю, что все персональные данные, полученные от
                зарегистрированных Пользователей сайта/Покупателей, будут обрабатываться в
                соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных
                данных».
            </p>
            <p>
                <strong>3. Права Администрации сайта/Поставщика</strong>
            </p>
            <p>
                3.1. В любое время изменять оформление и покупательский интерфейс Сайта,
                его содержание, предоставляемые функции, изменять или дополнять
                используемые скрипты, программное обеспечение, контент и другие объекты,
                используемые или хранящиеся на Сайте, любые Приложения и серверные
                приложения, с уведомлением Пользователей сайта/Покупателей или без
                такового;
            </p>
            <p>
                3.2.Удалять без предупреждения контент, который по усмотрению Администрации
                сайта/Поставщика сайта нарушает и/или может нарушать законодательство РФ,
                положения настоящих Правил, права Пользователей сайта/Покупателей или
                третьих лиц, причинять им вред или угрожать безопасности;
            </p>
            <p>
                3.3. По своему усмотрению удалять любую информацию, в том числе размещаемую
                Пользователем сайта/Покупателем на Сайте с нарушением норм законодательства
                РФ или положений настоящих Правил;
            </p>
            <p>
                3.4. Удалять аккаунт Пользователя сайта/Покупателя по своему усмотрению, в
                том числе, в случае совершения Пользователем сайта/Покупателем действий,
                нарушающих законодательство РФ или положения настоящих Правил;
            </p>
            <p>
                3.5. В целях сбора статистических данных и идентификации Пользователя
                сайта/Покупателя устанавливать и сохранять информацию об IP-адресах доступа
                Пользователя сайта/Покупателя на Сайт, использовать файлы технической
                информации (cookies), размещаемые на персональном компьютере Пользователя
                сайта/Покупателя;
            </p>
            <p>
                3.6. При использовании Сайта делать замечания Пользователям
                сайта/Покупателям, предупреждать, уведомлять, информировать их о
                несоблюдении настоящих Правил. Указания Администрации сайта/Поставщика,
                данные Пользователю сайта/Покупателю во время процесса использования Сайта,
                обязательны для исполнения Пользователем сайта/Покупателем;
            </p>
            <p>
                3.7. Предпринимать не запрещенные законом меры для защиты собственных
                интеллектуальных прав в отношении Сайта.
            </p>
            <p>
                <strong>4. Обязательства Пользователя сайта/Покупателя</strong>
            </p>
            <p>
                4.1. Пользователь сайта/Покупатель соглашается не предпринимать действий,
                которые могут рассматриваться как нарушающие российское законодательство
                или нормы международного права, в том числе в сфере
                <a target="_blank" href="https://copyright.ru/intellectual/">
                    интеллектуальной собственности
                </a>
                ,
                <a target="_blank" href="https://copyright.ru/ru/documents/avtorskoe_pravo/avtorskie_prava/">
                    авторских
                </a>
                и/или
                <a target="_blank" href="https://copyright.ru/ru/documents/avtorskoe_pravo/smegnie_prava/">
                    смежных правах
                </a>
                , а также любых действий, которые приводят или могут привести к нарушению
                нормальной работы Сайта и сервисов Сайта.
            </p>
            <p>
                4.2. Использование материалов Сайта без согласия
                <a target="_blank" href="https://copyright.ru/documents/avtorskoe_pravo/pravoobladateli/">
                    правообладателей
                </a>
                не допускается (статья 1270 ГК РФ). Для правомерного использования
                материалов Сайта необходимо заключение
                <a target="_blank" href="https://copyright.ru/ru/documents/avtorskoe_pravo/peredacha_avtorskih_prav/">
                    лицензионных договоров
                </a>
                (получение лицензий) от Правообладателей.
            </p>
            <p>
                4.3. При
                <a target="_blank" href="https://copyright.ru/library/zakonodatelstvo/gk_rf_obschee_zakonodatel/grazhdanskii_kodeks_RF_4_chast/glava_70__avtorskoe_pravo/#20">
                    цитировании
                </a>
                материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт
                обязательна (подпункт 1 пункта 1 статьи 1274 ГК РФ).
            </p>
            <p>
                4.4. Комментарии и иные записи Пользователя сайта/Покупателя на Сайте не
                должны вступать в противоречие с требованиями законодательства Российской
                Федерации и общепринятых норм морали и нравственности.
            </p>
            <p>
                4.5. Пользователь сайта/Покупатель предупрежден о том, что Администрация
                сайта/Поставщик не несет ответственности за посещение и использование им
                внешних ресурсов, ссылки на которые могут содержаться на сайте.
            </p>
            <p>
                4.6. Пользователи сайта/Покупатели самостоятельно несут ответственность и
                риск неблагоприятных последствий за предоставление неверных, либо
                недостоверных сведений, в том числе указанные при оформлении заявки
                параметры заказа, адреса доставки и прочих данных, повлекшее за собой
                невозможность надлежащего исполнения Администрацией сайта/Поставщиком своих
                обязательств перед Пользователем сайта/Покупателем.
            </p>
            <p>
                4.6. Пользователь сайта/Покупатель согласен с тем, что ни при каких
                обстоятельствах Администрация сайта/Поставщик или любая иная сторона,
                участвовавшая в создании, производстве или поддержке данного сайта, не
                несут ответственности за какой-либо ущерб (включая, без ограничений,
                прямой, случайный, опосредованный, косвенный ущерб, штрафы или убытки,
                связанные с упущенной выгодой, утратой данных или прерыванием ведения
                бизнеса), явившийся следствием того, что Вы имели доступ, использовали или
                не могли получить доступ или использовать данный сайт. Указанное
                ограничение ответственности также включает и возможный ущерб Вашему
                компьютерному оборудованию и обеспечению.
            </p>
            <p>
                4.7. Пользователь сайта/Покупатель принимает положение о том, что все
                материалы и сервисы Сайта или любая их часть могут сопровождаться рекламой.
                Пользователь сайта/Покупатель согласен с тем, что Администрация
                сайта/Поставщик не несет какой-либо ответственности и не имеет каких-либо
                обязательств в связи с такой рекламой.
            </p>
            <p>
                4.8. При работе на сайте Пользователю сайта/Покупателю запрещено отправлять
                или передавать какие-либо незаконные, угрожающие, клеветнические,
                непристойные, подстрекательские, порнографические или оскорбляющие
                материалы, которые могут представлять собой или побуждать к поведению,
                которое можно рассматривать как уголовное преступление, давать повод к
                возникновению гражданской ответственности или иным образом нарушать закон.
                Администрация сайта/Поставщик оставляет за собой право, но не обязанность,
                анализировать передаваемые данные и материалы, которые отправляются или
                закачиваются на сайт, и блокировать или удалять сообщения и материалы,
                которые, по мнению Администрации сайта/Поставщика, являются:
            </p>
            <p>
                - насильственными, клеветническими или непристойными,
            </p>
            <p>
                - мошенническими, обманными или вводящими в заблуждение,
            </p>
            <p>
                - противоречат авторским правам, правам на товарный знак или нарушают права
                на иную интеллектуальную собственность других лиц,
            </p>
            <p>
                - оскорбительными, нежелательными или по иной причине неприемлемыми для
                Администрации сайта/Поставщика, по собственному усмотрению компании. Кроме
                того, Администрация сайта/Поставщик будет целиком и полностью оказывать
                поддержку всем правоохранительным органам и иным органам, запрашивающим или
                требующим от Администрации сайта/Поставщика раскрыть личность любого лица,
                отправившего любое подобное сообщение или материалы.
            </p>
            <p>
                <strong>5. Обработка персональных данных</strong>
            </p>
            <p>
                5.1. Обработка персональных данных Пользователя сайта/Покупателя
                осуществляется в соответствии с законодательством РФ. Администрация
                сайта/Поставщик обрабатывает персональные данные Пользователя
                сайта/Покупателя в целях предоставления доступа к использованию функционала
                Сайта; проверки, исследования и анализа таких данных, позволяющих
                поддерживать и улучшать функционал и разделы Сайта, а также разрабатывать
                новый функционал и разделы Сайта. Администрация сайта/Поставщик принимает
                все необходимые меры для защиты персональных данных Пользователя
                сайта/Покупателя от неправомерного доступа, изменения, раскрытия или
                уничтожения. Администрация сайта/Поставщик предоставляет доступ к
                персональным данным Пользователя сайта/Покупателя только тем работникам,
                подрядчикам и агентам Администрации сайта/Поставщика, которым эта
                информация необходима для обеспечения функционирования Сайта и
                предоставления Пользователю сайта/Покупателю доступа к его использованию.
                Администрация сайта/Поставщик вправе использовать предоставленную
                Пользователем сайта/Покупателем информацию, в том числе персональные
                данные, в целях обеспечения соблюдения требований действующего
                законодательства РФ (в том числе в целях предупреждения и/или пресечения
                незаконных и/или противоправных действий Пользователей сайта/Покупателей).
                Раскрытие предоставленной Пользователем сайта/Покупателем информации может
                быть произведено лишь в соответствии с действующим законодательством РФ по
                требованию суда, правоохранительных органов, а равно в иных предусмотренных
                законодательством РФ случаях. Поскольку Администрация сайта/Поставщик
                осуществляет обработку персональных данных Пользователя сайта/Покупателя в
                целях исполнения настоящих Правил, в силу положений законодательства о
                персональных данных согласие Пользователя сайта/Покупателя на обработку его
                персональных данных не требуется.
            </p>
            <p>
                <strong>6. Прочие условия</strong>
            </p>
            <p>
                6.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные
                с ним, подлежат разрешению в соответствии с действующим законодательством
                Российской Федерации.
            </p>
            <p>
                6.2. Ничто в Соглашении не может пониматься как установление между
                Пользователем сайта/Покупателем и Администрации сайта/Поставщиком агентских
                отношений, отношений товарищества, отношений по совместной деятельности,
                отношений личного найма, либо каких-то иных отношений, прямо не
                предусмотренных Соглашением.
            </p>
            <p>
                6.3. Признание судом какого-либо положения Соглашения недействительным или
                не подлежащим принудительному исполнению не влечет недействительности иных
                положений Соглашения.
            </p>
            <p>
                6.4. Бездействие со стороны Администрации сайта/Поставщика в случае
                нарушения кем-либо из Пользователей сайта/Покупателей положений Соглашения
                не лишает Администрацию сайта/Поставщика права предпринять позднее
                соответствующие действия в защиту своих интересов и
                <a target="_blank" href="https://copyright.ru/documents/zashita_avtorskih_prav/">
                    защиту авторских прав
                </a>
                на охраняемые в соответствии с законодательством материалы Сайта.
            </p>
            <p>
                <strong>
                    Пользователь сайта/Покупатель подтверждает, что ознакомлен со всеми
                    пунктами настоящего Соглашения и безусловно принимает их.
                </strong>
            </p>
            <p>
                Заходя и используя данный сайт любым из возможных способов означает, что
                Пользователь сайта/Покупатель подтверждает, что ознакомлен, понял и
                согласен с Покупательским соглашением, полностью принимает его без
                каких-либо ограничений или оговорок и обязуется соблюдать все
                вышеприведенные условия настоящего Соглашения в соответствии со ст. 438
                Гражданского кодекса Российской Федерации.
            </p>
            <p>
                Если Пользователь сайта/Покупатель не согласен с Покупательским
                соглашением, то он не можете пользоваться данным Сайтом.
            </p>
        </div>

        <p>&nbsp;</p>

        <p class="t-a-center">
            <button class="btn btn--main" (click)="print()">Печать соглашения</button>
        </p>


        <button class="modal__close" type="button" (click)="this.close.emit(true)">
            <svg class="svg-icon" viewBox="0 0 1 1" width="16" height="16">
                <use xlink:href="/assets/img/sprite.svg#cross-bold"></use>
            </svg>
        </button>
    </div>

</div>
