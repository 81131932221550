<aside class="sidebar">
    <nav class="menu" [class.menu--hidden]="mobNavBar.mobMainNavHidden">
        <ul class="menu__list">
            <li class="menu__list-item">
                <a class="menu__item" routerLink="/" routerLinkActive="menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#house"></use>
                    </svg>
                    <span class="menu__caption">Бренд</span>
                </a>
            </li>
            <li class="menu__list-item">
                <a class="menu__item" routerLink="/page/products" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#knives"></use>
                    </svg>
                    <span class="menu__caption">Продукция</span>
                </a>
            </li>
            <li class="menu__list-item">
                <a class="menu__item" routerLink="/page/quality-service" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#premium"></use>
                    </svg>
                    <span class="menu__caption">Качество и сервис</span>
                </a>
            </li>
            <li class="menu__list-item">
                <a class="menu__item" routerLink="/page/useful" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#info"></use>
                    </svg>
                    <span class="menu__caption">Истины</span>
                </a>
            </li>
            <li class="menu__list-item menu__list-item--with_bg">
                <a class="menu__item" routerLink="/calculator" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#calculator"></use>
                    </svg>
                    <span class="menu__caption">Калькулятор</span>
                </a>
            </li>
            <li class="menu__list-item menu__list-item--with_bg">
                <a class="menu__item" routerLink="/orders" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#document"></use>
                    </svg>
                    <span class="menu__caption">Заказы и расчеты</span>
                </a>
            </li>
            <li class="menu__list-item menu__list-item--with_bg">
                <a class="menu__item" routerLink="/user-area" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#user"></use>
                    </svg>
                    <span class="menu__caption">Личный кабинет</span>
                </a>
            </li>
            <li class="menu__list-item">
                <a class="menu__item" routerLink="/contacts" routerLinkActive="menu__item--active">
                    <svg class="svg-icon" viewBox="0 0 1 1" width="24" height="24">
                        <use xlink:href="/assets/img/sprite.svg#phone-two-ribbon"></use>
                    </svg>
                    <span class="menu__caption">Контакты</span>
                </a>
            </li>
        </ul>
        <div class="flex flex-wrap a-i-center j-c-center hidden-on-lg">
            <a class="inline-flex a-i-center m-t-24 text-link" href="tel:+{{ config.phoneOnlyDigits }}">
                <svg class="svg-icon main-color" viewBox="0 0 1 1" width="18" height="18">
                    <use xlink:href="/assets/img/sprite.svg#phone-one-ribbon"></use>
                </svg>
                <span class="m-l-8">{{ config.phone }}</span>
            </a>
            <button class="btn btn--main m-t-12" type="button" routerLink="/contacts">
                Написать нам
            </button>
        </div>
    </nav>
    <div class="copyright hidden-on-sm">{{ currentYear }} © Компания JustCut<br>
        <span class="secondary-text-color">Все права защищены</span>
    </div>
</aside>
