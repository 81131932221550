<h1 class="text-level-1 t-a-center m-b-on-lg-48">Авторизация</h1>

<form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="flex flex-wrap m-t-on-lg-48">
        <div class="form-group">
            <input class="form-element form-element--filled" id="login-login" type="text" formControlName="email"  />
            <label class="form-label" for="login-login">
                Логин (e-mail)<span class="danger-color">*</span>&nbsp;&nbsp;
                <span class="err-msg">{{ loginForm.controls.email['errorMessage'] }}</span>
            </label>
        </div>

        <div class="form-group">
            <input class="form-element form-element--filled" id="password-login" type="password" formControlName="password" appInputPassword />
            <label class="form-label" for="password-login">
                Пароль<span class="danger-color">*</span>&nbsp;&nbsp;
                <span class="err-msg">{{ loginForm.controls.password['errorMessage'] }}</span>
            </label>
        </div>

        <div class="form-group">
            <p class="f-s-13">Забыли пароль?&#32;<a routerLink="/reset-pass">Восстановление пароля</a></p>
            <p class="f-s-13">Если вы новый пользователь - <a routerLink="/register">Зарегистрируйтесь</a></p>
        </div>
    </div>
    <div class="flex j-c-center">
        <button class="btn btn--main btn--lg m-t-on-lg-24" type="submit">Войти</button>
    </div>
</form>
