import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-text-page',
  templateUrl: './text-page.component.html',
  styleUrls: ['./text-page.component.css']
})
export class TextPageComponent implements OnInit {
    @HostBinding('class') classes = 'content';

    page = '';

    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void {
      this.page = this.route.snapshot.url[1].path;

    }

}
