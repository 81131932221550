import {maxNumber, minNumber, numeric, prop, propArray, range, required} from '@rxweb/reactive-form-validators';
import {Element} from './element';
import {TypeCircuits} from './enums/type-circuits.enum';

export class Order {
    uidorder1c: string; // UID заказа/расчета из 1С
    orderid: number; // уникальный номер заказа для расчета – 0 из 1С
    typeorder = 0; // 0 расчет по умолч. 1 заказ

    @prop()
    nameorder: string; // номер инструмента (название заказа)

    @prop()
    rushorder = 0; // срочный заказ, 0 – не срочный, 1 - срочный
    dateorder: string; // дата заказа

    @prop()
    shippingdate = ''; // дата отгрузки, в формате «yyyyMMdd»

    uidcontragent1c: string; // UID контрагента

    sumorder: number; // сумма заказа/расчета из 1С
    statusorder: string; // статус заказа/расчета

    delivery: number; // доставка, 1 – доставка, 0 - самовывоз
    deliveryaddress: string; // адрес доставки, обязательно к заполнению если delivery = 1

    @prop()
    comments = ''; // комментарий обычный

    speccomments: string; // комментарий к спецификации, может быть пустой. Не путать с полем comments. Поле speccomments – другое поле.

    invoice: string; // строка двоичных данных файла PDF, может быть пустой. Имя должно формироваться как «Счет на оплату заказа №» + orderid, хотя это вопрос нужно уточнить у Павла Беляева

    @required()
    typecircuit: number; // тип контура: 1 - круг, 2- прямоугольник, 3 - свободный

    @required()
    typefelling: number; // тип вырубки: 1 - ротация, 2- полуротация, 3 - плоский

    @minNumber({value: 1})
    @maxNumber({value: 800, conditionalExpression:(x,y) => x.typefelling < 3 })
    shaftpitch: number; // шаг печатного вала

    @prop()
    @minNumber({value: 1})
    knifelength: number; // длина ножа: задается только для полуротации, в остальных случаях равен 0, длина ножа для типа вырубки «плоский» пишется в «шаг печатного вала»

    @range({minimumNumber: 1, maximumNumber: 800})
    knifewidth: number; // ширина ножа: расчетная величина, кроме амебы – задается на форме, и круга и прямоугольника, если у круга и прямоугольника параметр manualknifewidth = 1

    @range({minimumNumber: 300, maximumNumber: 2000})
    knifeheight: number; // высота ножа

    @prop()
    antiadhesioncoating = 0; // антиадгезионное покрытие (RAA), 0 – ложь, 1 - истина

    @prop()
    laserhardening = 0; // лазерная закалка (RLL), 0 – ложь, 1 - истина

    @range({minimumNumber: 10, maximumNumber: 200})
    substratethickness: number; // толщина подложки вырубаемого материала

    @required()
    @range({minimumNumber: 60, maximumNumber: 120})
    anglesharpening: number; // угол заточки

    knifemileagetotal: number; // суммарный пробег ножа в километрах
    knifeturntotal: number; // суммарное количество оборотов ножа

//   Руьев Етикеток и расстояие А1 А2: ~~~~~~~~~~~~~~~~~~~~~~~~
    @numeric()
    @minNumber({value: 1})
    @required({conditionalExpression:(x) => { return x.typecircuit != TypeCircuits.Ameba }})
    amountstreams: number; // (Количество ручьев)

    @numeric()
    @minNumber({value: 1})
    @required({conditionalExpression:(x) => { return x.typecircuit != TypeCircuits.Ameba }})
    amountrepetitionsrapport: number; // (Этикеток в ручье) количество повторений на раппорте


    @prop()
    @minNumber({value: 1.2})
    distancestreams: number; // А1 (Расстояние между ручьями) 0 – если, количество ручьев равно 1

    @prop()
    @minNumber({value: 1.2})
    distancerepetitionsrapport: number; // А2 (Расстояние между повторениями): 0 – если, количество повторений равно 1

//  1 круг
    @range({minimumNumber: 1, maximumNumber: 800})
    @required({conditionalExpression:(x) => { return x.typecircuit == TypeCircuits.Circle }})
    diameter: number; // диаметр круга

//  2 прямоугольник
    @range({minimumNumber: 1, maximumNumber: 800})
    @required({conditionalExpression:(x) => { return x.typecircuit == TypeCircuits.Rectangle }})
    width: number; // ширина прямоугольника

    @range({minimumNumber: 1, maximumNumber: 800})
    @required({conditionalExpression:(x) => { return x.typecircuit == TypeCircuits.Rectangle }})
    length: number; // длина прямоугольника

    @required({conditionalExpression:(x) => { return x.typecircuit == TypeCircuits.Rectangle }})
    @minNumber({ value: 0})
    @maxNumber({ dynamicConfig: (x, y) => {
        const minSize = x.length < x.width ? x.length : x.width;
        return { value: minSize / 2, message: `от 0 до половины меньшей стороны прямоугольника` };
    }})
    radiusfillet: number; // радиус скругления, может быть равен 0

//  3 свободный
    @prop()
    unlimknife = 0; // бесконечный нож, 0 – ложь, 1 - истина

    @prop()
    perforation = 0; // перфорация, 0 – ложь, 1 - истина

    @prop()
    @required({conditionalExpression:(x) => { return x['perforation'] == 1 }})
    @range({minimumNumber: 100, maximumNumber: 1000})
    perforationheight: number; // высота перфорации (мкм) когда perforation = 1

    @prop()
    microperforation = 0; // необходимость микроперфорации, 0 – нет, 1 - да

    @prop()
    @required({conditionalExpression:(x) => { return x['microperforation'] == 1 }})
    qtycut: number; // количество резов (мкм) когда microperforation = 1

    @prop()
    multipleedgeheights = 0; // несколько высот кромки, 0 – ложь, 1 - истина

    @prop()
    multipleanglessharpening = 0; // несколько углов заточки, 0 – ложь, 1 - истина

    @prop()
    stringbinarydata: string;

    @prop()
    filename: string;

    @propArray(Element)
    elements: Element[]; //  массив элементы, максимально 10


    edge2mm: number; // кромка менее 2 мм, 0 – ложь, 1 - истина
    manualknifewidth: number; // ручная ширина ножа: 0 – нет, 1 - да

    repeatorder: number;
    numberrepeatorder: number;


    shown: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
        this.elements = this.elements || new Array<Element>();
    }

    statusIn(statuses) {
        return statuses.includes(this.statusorder.toLowerCase());
    }

    statusNotIn(statuses) {
        return !this.statusIn(statuses);
    }
}
//https://docs.rxweb.io/decorators/propArray/decorators
