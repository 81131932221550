import {email, mask, minLength, prop, required, requiredTrue} from '@rxweb/reactive-form-validators';

export class Message {
    @required()
    first_name: string;

    @prop()
    last_name: string;

    @required()
    @email()
    email: string;

    @prop()
    telefon: string;

    @required()
    message: string;

    @prop()
    @requiredTrue({ message: 'Пожалуйста, дайте согласие на обработку данных' })
    agreement: boolean;
}
